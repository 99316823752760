import React from "react";
import { useNavigate } from "react-router-dom";
import { useMain } from "../config/context/MainContext";
import { Link } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";

const Header = () => {
  let navigate = useNavigate();
  const { setToken, user, setUser } = useMain();
  let root = window.location.origin;

  console.log("user", user);

  const signOut = () => {
    setToken("");
    setUser({});
    navigate("/signin");
    secureLocalStorage.clear();
  };

  return (
    <div className="header">
      <div className="logo logo-dark">
        <Link to={"/"}>
          <img src={root + "/assets/images/logo/logo.png"} alt="Logo" />
          <img
            className="logo-fold"
            src={root + "/assets/images/logo/logo-fold.png"}
            alt="Logo"
          />
        </Link>
      </div>
      <div className="logo logo-white">
        <Link to={"/"}>
          <img src={root + "/assets/images/logo/logo-white.png"} alt="Logo" />
          <img
            className="logo-fold"
            src={root + "/assets/images/logo/logo-fold-white.png"}
            alt="Logo"
          />
        </Link>
      </div>
      <div className="nav-wrap">
        <ul className="nav-left">
          <li className="desktop-toggle">
            <a className="pointer" hrefLang="javascript:void(0)">
              <i className="anticon" />
            </a>
          </li>
          <li className="mobile-toggle">
            <a className="pointer" hrefLang="javascript:void(0)">
              <i className="anticon" />
            </a>
          </li>
        </ul>
        <ul className="nav-right">
          {/* <li className="dropdown dropdown-animated scale-left">
            <a hrefLang='javascript:void(0)' className="pointer" data-toggle="dropdown">
              <i className="anticon anticon-bell notification-badge" />
            </a>
            <div className="dropdown-menu pop-notification">
              <div className="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                <p className="text-dark font-weight-semibold m-b-0">
                  <i className="anticon anticon-bell" />
                  <span className="m-l-10">Notification</span>
                </p>
                <a hrefLang='javascript:void(0)' className="pointer btn-sm btn-default btn">
                  <small>View All</small>
                </a>
              </div>
              <div className="relative">
                <div className="overflow-y-auto relative scrollable" style={{maxHeight: 300}}>
                  <a hrefLang='javascript:void(0)' className="dropdown-item d-block p-15 border-bottom">
                    <div className="d-flex">
                      <div className="avatar avatar-blue avatar-icon">
                        <i className="anticon anticon-mail" />
                      </div>
                      <div className="m-l-15">
                        <p className="m-b-0 text-dark">You received a new message</p>
                        <p className="m-b-0"><small>8 min ago</small></p>
                      </div>
                    </div>
                  </a>
                  <a hrefLang='javascript:void(0)' className="dropdown-item d-block p-15 border-bottom">
                    <div className="d-flex">
                      <div className="avatar avatar-cyan avatar-icon">
                        <i className="anticon anticon-user-add" />
                      </div>
                      <div className="m-l-15">
                        <p className="m-b-0 text-dark">New user registered</p>
                        <p className="m-b-0"><small>7 hours ago</small></p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </li> */}
          <li className="dropdown dropdown-animated scale-left">
            <div className="pointer" data-toggle="dropdown">
              <div className="avatar avatar-image  m-h-10 m-r-15 avatar-blue">
                <i className="anticon anticon-user" />
              </div>
            </div>
            <div className="p-b-15 p-t-20 dropdown-menu pop-profile">
              <div className="p-h-20 p-b-15 m-b-10 border-bottom">
                <div className="d-flex m-r-5">
                  <div className="avatar avatar-lg avatar-image avatar-blue">
                    <i className="anticon anticon-user" />
                  </div>
                  <div className="m-l-10">
                    <p className="m-b-0 text-dark font-weight-semibold capitalize">
                      {user ? user.name : "name"}
                    </p>
                    <p className="m-b-0 opacity-07 capitalize">
                      {user ? user.role : "role"}
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="dropdown-item d-block p-h-15 p-v-10"
                onClick={() =>
                  navigate(
                    user.role === "super-admin" ? "/admin/profile" : "/profile"
                  )
                }
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <i className="anticon opacity-04 font-size-16 anticon-user" />
                    <span className="m-l-10">Edit Profile</span>
                  </div>
                  <i className="anticon font-size-10 anticon-right" />
                </div>
              </button>
              <button
                onClick={signOut}
                className="dropdown-item d-block p-h-15 p-v-10"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <i className="anticon opacity-04 font-size-16 anticon-logout" />
                    <span className="m-l-10">Logout</span>
                  </div>
                  <i className="anticon font-size-10 anticon-right" />
                </div>
              </button>
            </div>
          </li>
          <li>
            <a
              hrefLang="javascript:void(0)"
              className="pointer"
              style={{ display: "none" }}
              data-toggle="modal"
              data-target="#right-drawer"
            >
              <i className="anticon anticon-appstore" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
