import React, { useLayoutEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { initiateDatatable, toast, formatDate, initiateDatepicker, toggleModal } from '../../../utils/Helper'
import { API_PATH } from '../../../utils/Constant'
import { fetchGet, fetchPost } from '../../../utils/API'
import LoadingComponent from '../../../components/LoadingComponent'
import RightDrawer from '../../../components/RightDrawer'
import ReactTooltip from 'react-tooltip'

const AdminMasterCompany = () => {
  const navigate = useNavigate()
  const [companyList, setCompanyList] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [editCompanyId, setEditCompanyId] = useState('');
  const [companyField , setCompanyField] = useState({
    company_name: '',
    company_email: '',
    company_street: '',
    company_city: '',
    company_state: '',
    company_postal_code: '',
    company_country: '',
    company_domain: '',
    company_business_section: '',
    company_transaction_fee: ''
  })

  useLayoutEffect( () => {
    const fetchData = async () => {
      setLoadingScreen(true)
      var res = await fetchGet(API_PATH.MASTER_COMPANY)
      if(!res.success) {
        toast(res.message,res.success)
        if(res.status===401) navigate('/signin', { state: { ...res } })
      } else {
        setCompanyList(res.data)
        initiateDatatable({
          currencyCols:[5],
          filterTextCols:[0,1,2,3,4,5],
          filterSelectCols:[],
          filterDateCols:[6]
        })
        initiateDatepicker()
      }
      setLoadingScreen(false)
    }
    fetchData()
    
  },[initiateDatatable, navigate])

  const companyInputsHandler = (e) => {
    const { name, value } = e.target;
    setCompanyField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm(name, value)
  }

  const validateForm = (name, value) => {
    let data = {...companyField}
    console.log('companyField',data)
    data[name] = value
    const isNotEmptyData = Object.values(data).every(value => {
      if (value !== null && value !== "") {
        return true;
      }
      return false;
    });
    console.log(name, value, isNotEmptyData, data)
    setDisabled(!isNotEmptyData)
  }

  const resetCompanyField = () => {
    let temp = {}
    Object.keys(companyField).forEach(key => {temp[key] = ""})
    setCompanyField(temp)
  }

  const onCreateCompany = async () => {
    setLoading(true)
    var res = await fetchPost(API_PATH.CREATE_COMPANY, companyField)
    if(res.success) {
      var coTemp = [...companyList, {...companyField}]
      setCompanyList(coTemp)
      toggleModal(`#create_company_form`)
      resetCompanyField()
    }
    toast(res.message,res.success)
    setLoading(false)
  }

  const onUpdateCompany = async () => {
    setLoading(true)
    var res = await fetchPost(API_PATH.UPDATE_COMPANY, {...companyField, company_id:editCompanyId})
    if(res.success) {
      var coTemp = companyList.map(dt => {
        console.log(dt.id === editCompanyId)
        if(dt.id === editCompanyId){
          let temp = {}
          Object.keys(companyField).forEach(key => {temp[key.slice(8)] = companyField[key]})
          temp.id = editCompanyId
          return temp
        } else {
          return dt
        }
      })
      setCompanyList(coTemp)
      toggleModal(`#update_company_form`)
      resetCompanyField()
      setEditCompanyId('')
    }
    toast(res.message,res.success)
    setLoading(false)
  }

  const onDeleteCompany = async (id) => {
    var res = await fetchPost(API_PATH.DELETE_COMPANY, {company_id:id})
    if(res.success) {
      setCompanyList(companyList.filter(dt => dt.id !== id))
    }
    toast(res.message,res.success)
    ReactTooltip.hide()
  }

  const renderDeleteTooltipContent = (id) => {
    return (
      <>
        <p style={{display:'block'}}>Are you sure want to delete this data?</p>
        <div style={{display:'flex', justifyContent:'center'}}>
          <button className="btn btn-default btn-xs m-r-5"  onClick={()=>onDeleteCompany(id)}>
            Yes
          </button> 
          <button className="btn btn-default btn-xs" onClick={()=>ReactTooltip.hide()}>
            No
          </button> 
        </div>      
      </>
    )
  }

  const tableRow = (rowData, i) =>{
    return (
      <tr key={i}>
        <td>{rowData.name}</td> 
        <td>{rowData.email}</td>
        <td>{rowData.domain}</td>
        <td>{rowData.business_section}</td>
        <td>{rowData.street}, {rowData.city}, {rowData.state}, {rowData.country}, {rowData.postal_code} </td>
        <td>{rowData.transaction_fee}</td>
        <td>{formatDate(rowData.date_created)}</td>
        <td>
          <button className="btn btn-icon btn-info btn-rounded  m-t-5 m-r-5" data-toggle="modal" data-target="#update_company_form" onClick={()=>{
            let temp = {}
            Object.keys(companyField).forEach(key => {temp[key] = rowData[key.slice(8)]})
            setCompanyField(temp)
            setEditCompanyId(rowData.id)
          }}>
            <i className="anticon anticon-edit"></i>
          </button>
          <button className="btn btn-icon btn-danger btn-rounded  m-t-5 m-r-5" data-tip data-for={"deleteRow-"+i} data-event="click">
            <i className="anticon anticon-delete"></i>
          </button>
          <ReactTooltip id={'deleteRow-'+i} place='top' effect='solid' backgroundColor='#ffbebe' clickable={true}>
            {renderDeleteTooltipContent(rowData.id)}
          </ReactTooltip>
        </td>
      </tr>
    )
  }

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div className="card">
        <div className="card-header">
            <div className="d-md-flex m-b-15 align-items-center justify-content-between">
              <h4 className="card-title">Master Companies</h4>
              <button className="btn btn-primary" data-toggle="modal" data-target="#create_company_form" onClick={resetCompanyField}>
                <span>Add New</span>
              </button>
            </div>
          </div>
        <div className="card-body">    
          <div> 
            <div className="table-responsive">
              <table className="table table-hover data-table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Domain</th>
                    <th>Bussiness Section</th>
                    <th>Address</th>
                    <th>Transaction Fee</th>
                    <th>Created Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    companyList.map((trData,i) => {
                      return tableRow(trData,i)
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* create update form  */}
      <RightDrawer id='create_company_form' title='Add Company'>
        <div>
          <div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_name">Company Name:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_name" 
                  onChange={companyInputsHandler} 
                  placeholder="Name" 
                  value={companyField.company_name}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_email">Company Email:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_email" 
                  onChange={companyInputsHandler} 
                  placeholder="Email" 
                  value={companyField.company_email}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_street">Company Street:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_street" 
                  onChange={companyInputsHandler} 
                  placeholder="Street" 
                  value={companyField.company_street}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_city">Company City:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_city" 
                  onChange={companyInputsHandler} 
                  placeholder="Username" 
                  value={companyField.company_city}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_state">Company State:</label>
              <input 
                  type="text" 
                  className="form-control "
                  name="company_state" 
                  onChange={companyInputsHandler} 
                  placeholder="State" 
                  value={companyField.company_state}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_postal_code">Company Postal Code:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_postal_code" 
                  onChange={companyInputsHandler} 
                  placeholder="Postal Code" 
                  value={companyField.company_postal_code}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_country">Company Country:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_country" 
                  onChange={companyInputsHandler} 
                  placeholder="Country" 
                  value={companyField.company_country}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_domain">Company Domain:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_domain" 
                  onChange={companyInputsHandler} 
                  placeholder="Company Domain" 
                  value={companyField.company_domain}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_business_section">Company Business Section:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_business_section" 
                  onChange={companyInputsHandler} 
                  placeholder="Company Business Section" 
                  value={companyField.company_business_section}
                  required/>
            </div>   
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_transaction_fee">Company Transaction Fee:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_transaction_fee" 
                  onChange={companyInputsHandler} 
                  placeholder="Company Transaction Fee" 
                  value={companyField.company_transaction_fee}
                  required/>
            </div>             
            <div className='form-group'>
              <button
                className={["btn btn-primary ",loading?' is-loading':''].join(' ')} 
                onClick={onCreateCompany} 
                disabled={disabled}
              >
                <i className="anticon anticon-loading m-r-5"></i>
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </RightDrawer>
      <RightDrawer id='update_company_form' title='Edit Company'>
        <div>
          <div>
            <input 
                type="hidden" 
                className="form-control"
                name="company_id"
                value={editCompanyId}
                readOnly
                required/>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_name">Company Name:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_name" 
                  onChange={companyInputsHandler} 
                  placeholder="Name" 
                  value={companyField.company_name}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_email">Company Email:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_email" 
                  onChange={companyInputsHandler} 
                  placeholder="Email" 
                  value={companyField.company_email}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_street">Company Street:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_street" 
                  onChange={companyInputsHandler} 
                  placeholder="Street" 
                  value={companyField.company_street}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_city">Company City:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_city" 
                  onChange={companyInputsHandler} 
                  placeholder="Username" 
                  value={companyField.company_city}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_state">Company State:</label>
              <input 
                  type="text" 
                  className="form-control "
                  name="company_state" 
                  onChange={companyInputsHandler} 
                  placeholder="State" 
                  value={companyField.company_state}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_postal_code">Company Postal Code:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_postal_code" 
                  onChange={companyInputsHandler} 
                  placeholder="Postal Code" 
                  value={companyField.company_postal_code}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_country">Company Country:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_country" 
                  onChange={companyInputsHandler} 
                  placeholder="Country" 
                  value={companyField.company_country}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_domain">Company Domain:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_domain" 
                  onChange={companyInputsHandler} 
                  placeholder="Company Domain" 
                  value={companyField.company_domain}
                  required/>
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_business_section">Company Business Section:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_business_section" 
                  onChange={companyInputsHandler} 
                  placeholder="Company Business Section" 
                  value={companyField.company_business_section}
                  required/>
            </div>   
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="company_transaction_fee">Company Transaction Fee:</label>
              <input 
                  type="text" 
                  className="form-control"
                  name="company_transaction_fee" 
                  onChange={companyInputsHandler} 
                  placeholder="Company Transaction Fee" 
                  value={companyField.company_transaction_fee}
                  required/>
            </div>             
            <div className='form-group'>
              <button
                className={["btn btn-primary ",loading?' is-loading':''].join(' ')} 
                onClick={onUpdateCompany} 
                disabled={disabled}
              >
                <i className="anticon anticon-loading m-r-5"></i>
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </RightDrawer>      
    </>
  )
}

export default AdminMasterCompany