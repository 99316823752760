import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useMain } from "../../config/context/MainContext";
import { getPath, getTitle } from "../../utils/Helper";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import secureLocalStorage from "react-secure-storage";

const MainLayout = () => {
  const navigate = useNavigate();
  const { title, setTitle, user } = useMain();
  const location = useLocation(); //#1 must use this to get fire useEffect on every url change

  let path = getPath();
  let isAdmin = path.includes("admin");

  const _renderHeaderTitle = () => {
    if (["/wallet/in-out", "/wallet/history"].includes(location.pathname)) {
      return <h2 className="header-title">Wallet</h2>;
    }
    return (
      <>
        {((isAdmin && path.length > 2) || (!isAdmin && path.length > 1)) && (
          <button
            className="btn btn-secondary btn-tone m-r-20"
            onClick={() => navigate(-1)}
          >
            <i className="anticon anticon-arrow-left"></i> Back{" "}
          </button>
        )}
        <h2 className="header-title">{title}</h2>
      </>
    );
  };

  React.useEffect(() => {
    //#3 change title page
    if (user.role === "super-admin") path.shift();
    if (path.length === 1) {
      setTitle(getTitle());
    } else {
      if (path[0] === "transaction") setTitle("Transaction Detail");
    }
  }, [setTitle, location.key, path, user.role]); //#2

  React.useEffect(() => {
    // trigger the sidebar not open on mobile
    if (!secureLocalStorage.getItem("motra_first_login")) {
      setTimeout(() => {
        secureLocalStorage.setItem("motra_first_login", "true");
        window.location.reload();
      }, 1000);
    }
  }, []);

  return (
    <div className="app">
      <div className="layout">
        <Header />
        <Sidebar />
        <div className="page-container">
          <div className="main-content">
            <div className="page-header">
              {_renderHeaderTitle()}
              <div className="header-sub-title">
                <nav className="breadcrumb breadcrumb-dash">
                  {path[0] === "" ? (
                    path.map((d, i) => {
                      return (
                        <span key={i} className="breadcrumb-item capitalize">
                          {d}
                        </span>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </nav>
              </div>
            </div>
            <div
              className="notification-toast bottom-middle"
              id="notification-toast"
            />
            <div className="-tw-mt-10 md:tw-mt-0">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
