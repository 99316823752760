import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from '../../../utils/Helper'
import { API_PATH } from '../../../utils/Constant'
import { fetchPost } from '../../../utils/API'

function ForgotPassword() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputField , setInputField] = useState({
      email: '',
  })

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const submitButton = async (e) => {
    e.preventDefault()
    setLoading(true)
    var res = await fetchPost(API_PATH.FORGOT_PASSWORD, {...inputField})
    toast(res.message,res.success);
    setLoading(false)
    if(res.success) {
      setTimeout(function(){ 
        navigate('/signin')
      }, 1000);
    }
  }
  
  return (
    <div className="row w-100 m-0 align-items-center">
      <div className="col-md-7 col-lg-5 m-h-auto">   
        <div className="card shadow-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between m-b-30">
              <img className="img-fluid" alt="" src="assets/images/logo/logo.png" />
              <h3 className="m-b-0">Forgot Password</h3>
            </div>
            <form>
              <div className="d-flex align-items-center justify-content">Please input your email to reset your password</div>
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="userName">Email:</label>
                <div className="input-affix">
                  <i className="prefix-icon anticon anticon-user" />
                  <input 
                    type="text" 
                    className="form-control"
                    name="email" 
                    id="email"
                    onChange={inputsHandler} 
                    placeholder="Email" 
                    value={inputField.email}/>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="font-size-13 text-muted">
                    <i className="prefix-icon anticon anticon-arrow-left" />
                    <Link to="/signin" > Back</Link>
                  </span>
                  <button className={["btn btn-primary ",loading?' is-loading':''].join(' ')} onClick={submitButton}>
                    <i class="anticon anticon-loading m-r-5"></i>
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword