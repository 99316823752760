import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-content d-flex align-items-center justify-content-between">
          <p className="m-b-0">Copyright © 2022 Motra. All rights reserved.</p>
          <span>
            <Link to={''} className="text-gray m-r-15">Term &amp; Conditions</Link>
            <Link to={''} className="text-gray">Privacy &amp; Policy</Link>
          </span>
        </div>
      </footer>        
    )
  }
}

export default Footer