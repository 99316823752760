import React, { forwardRef, useEffect } from "react";
import { toast } from "../utils/Helper";

const Espay = forwardRef(({ topupData, id }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      if (topupData) {
        const { amount, UUID, previousRoute } = topupData;

        let data = {
          key: process.env.REACT_APP_ESPAY_API_KEY,
          paymentId: UUID,
          backUrl: previousRoute,
          paymentAmount: amount,
        };
        window.renderEspay(data);
      }
    }
  }, [topupData]);

  return (
    <div id={id} className="modal fade">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <h2 style={{ display: "contents", marginBottom: 20 }}>Payment</h2>
            <button type="button" className="close" data-dismiss="modal">
              <i className="anticon anticon-close" />
            </button>
            <br />
            {process.env.NODE_ENV === "development" ? (
              "Please run this on production mode"
            ) : (
              <iframe
                id="sgoplus-iframe"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
                src=""
                scrolling="no"
                frameborder="0"
                title="Espay Iframe"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Espay;
