import React, {useEffect, useState, useCallback} from 'react'
import { useNavigate } from 'react-router-dom'
import { initiateDatatable, toast, formatCurrency, stringCurrency, formatDate, initiateDatepicker } from '../../../utils/Helper'
import { API_PATH } from '../../../utils/Constant'
import { fetchGet,fetchPost } from '../../../utils/API'
import LoadingComponent from '../../../components/LoadingComponent'

import RightDrawer from '../../../components/RightDrawer'

const AdminWallet = () => {  
  const navigate = useNavigate()
  
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [walletData, setWalletData] = useState([{user:{}}]);
  const [bankData, setBankData] = useState([{bank:{}}]);
  const [summaryData, setSummaryData] = useState({
    balance: "",
    totalDeposit: "",
    numDeposit: "",
    totalWithdraw: "",
    numWithdraw: "",
    totalDisbursement: "",
    numDisbursement: "",
    numRefund: "",
    totalRefund: "",
  });

  const [withdrawField , setWithdrawField] = useState({
    amount: '',
    bank_account_id: ''
  })

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setWithdrawField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm(name, value)
  }

  const validateForm = (name, value) => {
    let data = withdrawField
    data[name] = value
    const isNotEmptyData = Object.values(data).every(value => {
      if (value !== null && value !== "") {
        return true;
      }
      return false;
    });
    setDisabled(!isNotEmptyData)
  }

  useEffect( () => {
    const fetchData = async () => {
      setLoadingScreen(true)
      var res = await fetchGet(API_PATH.WALLET)
      if(!res.success) {
        toast(res.message,res.success)
        if(res.status===401) navigate('/signin', { state: { ...res } })
      } else {
        setSummaryData(prev => ({
          ...prev,
          balance: res.data.balance,
          totalDeposit: res.data.totalDeposit,
          numDeposit: res.data.numDeposit,
          totalWithdraw: res.data.totalWithdraw,
          numWithdraw: res.data.numWithdraw,
          totalDisbursement: res.data.totalDisbursement,
          numDisbursement: res.data.numDisbursement,
          numRefund: res.data.numRefund,
          totalRefund: res.data.totalRefund,
        }))
        setWalletData(res.data.inOutList)

        initiateDatatable({
          currencyCols:[1,2],
          filterTextCols:[0,1,2],
          filterSelectCols:[3,4],
          filterDateCols:[5]
        })
        initiateDatepicker()

        setLoadingScreen(false)
      }
    }
    fetchData()
  },[initiateDatatable, navigate])

  const submitWithdraw = useCallback (async () => {
    setLoading(true)
    var res2 = await fetchPost(API_PATH.WITHDRAW, withdrawField )
    toast(res2.message,res2.success);
    if(!res2.success) { 
      setLoading(false)
    } else {
      setWithdrawField({amount:0,bank_account_id:''})
    }
  }, [withdrawField])

  const typeBadge = (data) => {
    if(data==='in') return <span className="badge badge-pill badge-green capitalize">{data}</span>
    else return <span className="badge badge-pill badge-red capitalize">{data}</span>
  }

  const categoryBadge = (data) => {
    if(data==='deposit') return <span className="badge badge-blue capitalize">{data}</span>
    else if(data==='refund') return <span className="badge badge-geekblue capitalize">{data}</span>
    else if(data==='disburse') return <span className="badge badge-orange capitalize">{data}</span>
    else if(data==='withdraw') return <span className="badge badge-volcano capitalize">{data}</span>
    else return <span className="badge badge-cyan capitalize">{data}</span>
  }

  const tableRow = (rowData, i) =>{
    return (
      <tr key={i}>
        <td>
          <span style={{textTransform:'uppercase'}}>#WLIO{rowData.id}</span>
        </td>
        <td>{rowData.nominal}</td> 
        <td>{rowData.current_total}</td>
        <td>{typeBadge(rowData.type)}</td>
        <td>{categoryBadge(rowData.category||'dummy')}</td>
        <td>{formatDate(rowData.date_created)}</td>
      </tr>
    )
  }

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div>
        <div className="row">
          {/* <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="d-md-flex align-items-center">
                  <div className="text-center text-sm-left ">
                    <div className="avatar avatar-image avatar-purple" style={{width: 150, height: 150, padding:20}}>
                      <i className="anticon anticon-wallet" style={{fontSize: 90, lineHeight:'normal'}}></i>
                    </div>
                  </div>
                  <div className="text-center text-sm-left m-v-15 p-l-30">
                    <h2 className="m-b-5">Deposit</h2>
                    <p className="text-opacity font-size-13">Balance</p>
                    <p className="text-dark m-b-20 font-weight-semibold font-size-30">Rp {formatCurrency(summaryData.balance)}</p>
                    <button className="btn btn-secondary  m-r-10" data-toggle="modal" data-target="#withdraw_form">Withdraw</button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-md-12 row" style={{margin:0}}>
            <div className="col-md-12 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-gold">
                      <i className="anticon anticon-dollar" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">Rp {stringCurrency(summaryData.totalDeposit)}</h2>
                      <p className="m-b-0 text-muted">Total Topup</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-cyan">
                      <i className="anticon anticon-dollar" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">Rp {stringCurrency(summaryData.totalWithdraw)}</h2>
                      <p className="m-b-0 text-muted">Total Withdraw</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
            <div className="col-md-12 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-purple">
                      <i className="anticon anticon-profile" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">Rp {stringCurrency(summaryData.totalDisbursement)}</h2>
                      <p className="m-b-0 text-muted">Total Disburse</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>                
            <div className="col-md-12 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                      <i className="anticon anticon-schedule" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">{summaryData.numDeposit}</h2>
                      <p className="m-b-0 text-muted">Number of Topup</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-purple">
                      <i className="anticon anticon-schedule" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">{summaryData.numWithdraw}</h2>
                      <p className="m-b-0 text-muted">Number of Withdraw </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>                          
            <div className="col-md-12 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-blue">
                      <i className="anticon anticon-profile" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">Rp {stringCurrency(summaryData.totalRefund)}</h2>
                      <p className="m-b-0 text-muted">Total Refund </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center m-b-50">
                  <h5>Wallet In Out</h5>
                  <div>
                    <div className="btn-group">
                      <button className="btn btn-default active">
                        <span>All History</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover data-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Amount</th>
                        <th>Balance</th>
                        <th>Type</th>
                        <th>Category</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        walletData.map((trData,i) => {
                          return tableRow(trData,i)
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightDrawer id='withdraw_form' title='Withdraw Form'>
          <div>
            <div>
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="amount">Amount:</label>
                <input 
                    type="text" 
                    className="form-control"
                    name="amount" 
                    id="amount"
                    onChange={inputsHandler} 
                    placeholder="Amount" 
                    value={withdrawField.amount}
                    required/>
              </div>
              <div className="form-group">
                <label htmlFor="bank_account">Bank Account</label>
                <select className="custom-select" name="bank_account_id" value={withdrawField.bank_account_id} onChange={inputsHandler}>
                  <option>Choose...</option>
                  {
                    bankData.map((data,i)=><option key={i} value={data.id}>{data.account_number}-{data.bank.name}</option>)
                  }
                </select>
              </div>
              <div className='form-group'>
                <button
                  className={["btn btn-primary ",loading?' is-loading':''].join(' ')} 
                  onClick={submitWithdraw}
                  disabled={disabled}
                >
                  <i className="anticon anticon-loading m-r-5"></i>
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </RightDrawer>
      </div>
    </>
  )
}

export default AdminWallet
