import React from "react";
import { useMain } from "../../../config/context/MainContext";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const PendingApproval = () => {
  let navigate = useNavigate();
  const { setToken, setUser } = useMain();

  const signOut = () => {
    setToken("");
    setUser({});
    navigate("/signin");
    secureLocalStorage.clear();
  };
  return (
    <div className="row w-100 m-0 align-items-center">
      <div className="col-md-7 col-lg-7 m-h-auto">
        <div className="card shadow-lg">
          <div className="card-body tw-flex tw-flex-col">
            <h1 className="tw-text-xl tw-font-medium tw-mb-4">
              Akun Dalam Proses
            </h1>
            <p>
              Harap menunggu, akun Anda sedang dalam proses Persetujuan dari
              Admin terlebih dahulu
            </p>
            <button
              className="btn btn-primary tw-mt-4 md:tw-w-[50%] tw-w-full tw-self-center"
              onClick={signOut}
            >
              Kembali Ke Halaman Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingApproval;
