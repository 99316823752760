import { twMerge } from "tailwind-merge";
import React from "react";

export const FilePickerInput = ({
  className,
  label,
  placeholder,
  fileName,
  onOpenFile,
  isLoading = false,
}) => {
  return (
    <div className={twMerge("tw-flex tw-flex-col tw-gap-1", className)}>
      <h6 className="tw-text-base tw-lg:text-lg tw-text-black font-medium">
        {label}
      </h6>
      <div className="tw-flex tw-gap-2">
        <div className="tw-w-[0%] tw-flex-1 tw-px-5 tw-py-2 tw-border-2 tw-border-[#ECECEC] tw-rounded-lg">
          <p
            className={twMerge(
              "tw-text-[#828282] tw-text-sm tw-italic tw-font-normal tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden",
              fileName && "tw-text-[#00529C] tw-underline"
            )}
          >
            {fileName || placeholder}
          </p>
        </div>
        <button
          className={twMerge("btn btn-primary", isLoading && "is-loading")}
          onClick={onOpenFile}
        >
          {isLoading ? (
            <i className="anticon anticon-loading"></i>
          ) : (
            <span>Pilih File</span>
          )}
        </button>
        {/* <Button
          aria-label="choose"
          onClick={onOpenFile}
          loading={onBtnLoading}
          className={`rounded-lg lg:px-10 h-10 py-0 bg-[#ECECEC] hover:border-0 hover:bg-[#ECECEC]`}
        >
          <span className="text-sm text-briBlackBrimo">Pilih File</span>
        </Button> */}
      </div>
    </div>
  );
};
