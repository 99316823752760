import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useFilePicker } from "use-file-picker";
import { FilePickerInput } from "../../../components/FilePickerInput";
import useImageCompressor from "../../../hooks/useImageCompressor";
import usePrevious from "../../../hooks/usePrevious";
import { fetchPostFormData } from "../../../utils/API";
import { API_PATH } from "../../../utils/Constant";
import { toast } from "../../../utils/Helper";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const EKYC = () => {
  let navigate = useNavigate();
  const [doc, setDocument] = useState({ ktp: null, selfiePhoto: null });
  const [loading, setLoading] = useState(false);

  const [openKTPFile, { plainFiles: ktpFiles }] = useFilePicker({
    accept: "image/*",
    multiple: false,
  });
  const [openSelfiePhotoFile, { plainFiles: selfiePhotos }] = useFilePicker({
    accept: "image/*",
    multiple: false,
  });
  const [onKTPCompressor, { isLoading: isKTPCompressorLoading }] =
    useImageCompressor();
  const [
    onSelfiePhotoCompressor,
    { isLoading: isSelfiePhotoCompressorLoading },
  ] = useImageCompressor();

  const ktp = useMemo(() => {
    return ktpFiles?.[0];
  }, [ktpFiles]);
  const prevKtp = usePrevious(ktp);

  const selfiePhoto = useMemo(() => {
    return selfiePhotos?.[0];
  }, [selfiePhotos]);
  const prevSelfiePhoto = usePrevious(selfiePhoto);

  const submitButton = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("ktp_file", doc.ktp);
    formData.append("ktp_selfie_file", doc.selfiePhoto);

    var res = await fetchPostFormData(API_PATH.VERIFY_EKYC_PERSONAL, formData);
    if (!res.success) {
      toast(res.message, res.success);
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
        navigate("/pending_approval");
      }, 1500);
    }
  };

  useEffect(() => {
    (async () => {
      if (ktp && !_.isEqual(prevKtp, ktp)) {
        try {
          const compressedFile = await onKTPCompressor(ktp);
          setDocument((prevState) => ({ ...prevState, ktp: compressedFile }));
        } catch (err) {
          const description =
            err?.message === "The file given is not an image"
              ? "Format file yang Anda berikan harus berupa gambar"
              : err?.message;
          console.log(description);
        }
      }
    })();
  }, [ktp, onKTPCompressor, prevKtp]);

  useEffect(() => {
    (async () => {
      if (selfiePhoto && !_.isEqual(prevSelfiePhoto, selfiePhoto)) {
        try {
          const compressedFile = await onSelfiePhotoCompressor(selfiePhoto);
          setDocument((prevState) => ({
            ...prevState,
            selfiePhoto: compressedFile,
          }));
        } catch (err) {
          const description =
            err?.message === "The file given is not an image"
              ? "Format file yang Anda berikan harus berupa gambar"
              : err?.message;
          console.log(description);
        }
      }
    })();
  }, [onSelfiePhotoCompressor, prevSelfiePhoto, selfiePhoto]);

  return (
    <div className="row w-100 m-0 align-items-center">
      <div className="col-md-7 col-lg-7 m-h-auto">
        <div className="card shadow-lg">
          <div className="card-body tw-flex tw-flex-col">
            <p>
              Mohon unggah / upload dokumen pendukung ini sebagai dukungan dan
              syarat usaha Anda dengan ketentuan sebagai berikut : Format file
              yang dapat diunggah adalah pdf, jpg, jpeg / png
            </p>

            <FilePickerInput
              className="tw-mt-4"
              label="Upload KTP"
              placeholder="No file selected"
              onOpenFile={openKTPFile}
              fileName={doc.ktp?.name}
              isLoading={isKTPCompressorLoading}
            />
            <FilePickerInput
              className="tw-mt-8"
              label="Upload KTP dan Selfie"
              placeholder="No file selected"
              onOpenFile={openSelfiePhotoFile}
              fileName={doc.selfiePhoto?.name}
              isLoading={isSelfiePhotoCompressorLoading}
            />
            <button
              disabled={!doc.ktp || !doc.selfiePhoto}
              className={twMerge(
                "btn btn-primary tw-mt-6",
                loading ? " is-loading" : ""
              )}
              onClick={submitButton}
            >
              <i className="anticon anticon-loading m-r-5"></i>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EKYC;
