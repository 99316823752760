import React, {useState} from 'react'
import secureLocalStorage from 'react-secure-storage';

const MainContext = React.createContext();
const useMain = () => React.useContext(MainContext);

const MainProvider = ({ children }) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [title, setTitleCtx] = useState('Dashboard');
  const [token, setTokenCtx] = useState(secureLocalStorage.getItem('token'));
  const [user, setUserCtx] = useState(JSON.parse(secureLocalStorage.getItem('user')));

  const setTitle = (title) => {
    document.title = 'Motra - '+title
    setTitleCtx(title)
  }

  const setToken = (token) => {
    secureLocalStorage.setItem("token", token)
    setTokenCtx(token)
  }

  const setUser = (user) => {
    secureLocalStorage.setItem("user", JSON.stringify(user))
    setUserCtx(user)
  }


  return (
    <MainContext.Provider value={{ title, setTitle, user, setUser, token, setToken, pageLoading, setPageLoading }}>
      {children}
    </MainContext.Provider>
  );
};

export {MainProvider, useMain}