import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { MainProvider, useMain } from "./config/context/MainContext";

import MainLayout from "./views/layout/MainLayout";
import PublicLayout from "./views/layout/PublicLayout";

import Dashboard from "./views/pages/customer/Dashboard";
import Profile from "./views/pages/customer/Profile";
import Wallet from "./views/pages/customer/Wallet";
import Topup from "./views/pages/customer/Topup";
import Logs from "./views/pages/customer/Logs";
import ApiDocumentation from "./views/pages/customer/ApiDocumentation";
import Transaction from "./views/pages/customer/Transaction";
import TransactionDetail from "./views/pages/customer/TransactionDetail";

import AdminDashboard from "./views/pages/admin/AdminDashboard";
import AdminProfile from "./views/pages/admin/AdminProfile";
import AdminWallet from "./views/pages/admin/AdminWallet";
import AdminLogs from "./views/pages/admin/AdminLogs";
import AdminTransaction from "./views/pages/admin/AdminTransaction";
import AdminTransactionDetail from "./views/pages/admin/AdminTransactionDetail";
import AdminMasterCompany from "./views/pages/admin/AdminMasterCompany";
import AdminMasterUser from "./views/pages/admin/AdminMasterUser";

import SignIn from "./views/pages/common/Signin";
import EKYC from "./views/pages/common/EKYC";
import SignUp from "./views/pages/common/Signup";
import Verify from "./views/pages/common/Verify";
import ForgotPassword from "./views/pages/common/ForgotPassword";

import NotAccessible from "./views/pages/common/NotAccessible";

// import Customers from "./views/pages/Customers";
// import TopupEspay from "./views/pages/espay/TopupEspay";

import "./index.css";
import WalletTabInOut from "./views/pages/customer/wallet-tab/InOut";
import WalletHistory from "./views/pages/customer/wallet-tab/History";
import PendingApproval from "./views/pages/common/PendingApproval";

function RequireAuthPersonal({ children }) {
  const { token, user } = useMain();
  return token && ["personal", "admin"].includes(user.role) ? (
    children
  ) : (
    <Navigate to="/signin" />
  );
}

function RequireAuthAdmin({ children }) {
  const { token, user } = useMain();
  if (token && ["admin", "personal"].includes(user.role)) {
    if (user.approval_status === "approve") {
      return children;
    } else if (
      user.role === "personal" &&
      (user.approval_status === null ||
        ["pending", "reject"].includes(user.approval_status))
    ) {
      return <Navigate to="/ekyc" />;
    } else {
      return <Navigate to="/pending_approval" />;
    }
  }
  return <Navigate to="/signin" />;
}

function RequireAuthSuperAdmin({ children }) {
  const { token, user } = useMain();
  return token && user.role === "super-admin" ? (
    children
  ) : (
    <Navigate to="/signin" />
  );
}

const App = () => {
  return (
    <MainProvider>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify/:code" element={<Verify />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
        </Route>
        <Route
          element={
            <RequireAuthPersonal>
              <PublicLayout />
            </RequireAuthPersonal>
          }
        >
          <Route path="ekyc" element={<EKYC />} />
          <Route path="pending_approval" element={<PendingApproval />} />
        </Route>
        <Route
          element={
            <RequireAuthAdmin>
              <MainLayout />
            </RequireAuthAdmin>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="transaction" element={<Transaction />} />
          <Route path="transaction/:code" element={<TransactionDetail />} />
          <Route path="profile" element={<Profile />} />
          <Route path="logs" element={<Logs />} />
          <Route path="wallet" element={<Wallet />}>
            <Route path="in-out" element={<WalletTabInOut />} />
            <Route path="history" element={<WalletHistory />} />
          </Route>
          <Route path="topup" element={<Topup />} />
          <Route path="api_documentation" element={<ApiDocumentation />} />
        </Route>
        <Route
          element={
            <RequireAuthSuperAdmin>
              <MainLayout />
            </RequireAuthSuperAdmin>
          }
        >
          <Route path="admin/dashboard" element={<AdminDashboard />} />
          <Route path="admin/transaction" element={<AdminTransaction />} />
          <Route
            path="admin/transaction/:code"
            element={<AdminTransactionDetail />}
          />
          <Route path="admin/profile" element={<AdminProfile />} />
          <Route path="admin/logs" element={<AdminLogs />} />
          <Route path="admin/wallet" element={<AdminWallet />} />
          <Route path="admin/companies" element={<AdminMasterCompany />} />
          <Route path="admin/users" element={<AdminMasterUser />} />
        </Route>
        {/* <Route path="topup/espay" element={<TopupEspay />} /> */}
        <Route path="/404" element={<NotAccessible />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </MainProvider>
  );
};

export default App;
