import { isDevelopment } from './Setting'
export const APIURL = isDevelopment ? "http://localhost:4000/api" : 'https://motra-api-dev-exoba.ondigitalocean.app'

export const API_PATH = {
    FORGOT_PASSWORD         : '/common/reset_password',
    BANK_LIST               : '/common/banks',
    ROLE_LIST               : '/common/roles',
    BUSINESS_SECTION_LIST   : '/common/business_sections',
    COUNTRY_LIST            : '/common/countries',
    PROVINCE_LIST           : '/common/provinces',
    CITY_LIST               : '/common/cities',
    DISTRICT_LIST           : '/common/districts',
    SUBDISTRICT_LIST        : '/common/subdistricts',
    SUBMIT_VERIFY           : '/common/verify',
    CURRENCIES              : '/common/currencies',

    SIGNIN                  : '/auth/login',
    REFRESH_TOKEN           : '/auth/refresh_token',
    SIGNOUT                 : '/auth/logout',

    SIGNUP                  : '/user/register',
    SIGNUP_PERSONAL         : '/user/register-personal',
    VERIFY_EKYC_PERSONAL    : '/user/verify_ekyc_personal',
    DASHBOARD               : '/user/dashboard',
    PROFILE                 : '/user/info',
    CHANGE_PASSWORD         : '/user/change_password',
    UPDATE_USER_PROFILE     : '/user/update',
    UPDATE_COMPANY_PROFILE  : '/user/update_company',
    REFRESH_API_TOKEN       : '/user/refresh_api_token',
    REFRESH_SB_API_TOKEN       : '/user/refresh_sb_api_token',
    BANK_ACCOUNT            : '/user/bank_account/list',
    CREATE_BANK_ACCOUNT     : '/user/bank_account/add',
    UPDATE_BANK_ACCOUNT     : '/user/bank_account/edit',
    DELETE_BANK_ACCOUNT     : '/user/bank_account/delete',
    WHITELIST_IP            : '/user/whitelist/list',
    CREATE_WHITELIST        : '/user/whitelist/add',
    DELETE_WHITELIST        : '/user/whitelist/delete',
    TOPUP_ADD               : '/user/topup/add',
    TOPUP_LIST              : '/user/topup/list',

    TRANSACTION             : '/transactions/list',
    SINGLE_TRANSACTION      : '/transactions/',
    WITHDRAW_DEPOSIT        : '/transactions/withdraw_deposit',
    
    REQUEST_OTP             : '/otp/request',
    CHECK_OTP               : '/otp/check/',

    WALLET                  : '/wallet/summary',
    WALLET_HISTORY          : '/wallet/history',
    WITHDRAW                : '/wallet/withdraw',
    CONFIRM_WITHDRAW        : '/wallet/withdraw/confirm',
    CANCEL_WITHDRAW         : '/wallet/withdraw/cancel',
    CONFIRM_DEPOSIT         : '/wallet/deposit/confirm',
    CANCEL_DEPOSIT          : '/wallet/deposit/cancel',
    CONFIRM_DISBURSEMENT    : '/wallet/disbursement/confirm',
    CANCEL_DISBURSEMENT    : '/wallet/disbursement/cancel',

    API_DOCS                : '/api_documentations/list',
    LOGS                    : '/logs/list',

    MASTER_USER             : '/user/list',
    CREATE_USER             : '/user/create',
    UPDATE_USER             : '/user/update',
    DELETE_USER             : '/user/delete',
    MASTER_COMPANY          : '/user/company/list',
    CREATE_COMPANY          : '/user/create_company',
    UPDATE_COMPANY          : '/user/update_company',
    DELETE_COMPANY          : '/user/company/delete',
}