import React, {useLayoutEffect, useState} from 'react'
import { initiateDatatable, initiateDatepicker } from '../../../utils/Helper'
import { API_PATH } from '../../../utils/Constant'
import { fetchGet } from '../../../utils/API'
import { formatDate, toast } from '../../../utils/Helper'
import { useParams, useNavigate } from 'react-router-dom';
import LoadingComponent from '../../../components/LoadingComponent'

const AdminTransactionDetail = () => {
  const navigate = useNavigate()
  let { code } = useParams();
  const [detailData, setDetailData] = useState([{bank: ""}]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  useLayoutEffect( () => {
    const fetchData = async () => {
      setLoadingScreen(true)
      var res = await fetchGet(API_PATH.SINGLE_TRANSACTION+code)
      if(!res.success) {
        toast(res.message,res.success)
        if(res.status===401) navigate('/signin', { state: { ...res } })
      } else {
        setDetailData(res.data.transaction_details)
        initiateDatatable({
          currencyCols:[],
          filterTextCols:[0,1,2,3,4],
          filterSelectCols:[5],
          filterDateCols:[6]
        })
        initiateDatepicker()
      }
      setLoadingScreen(false)
    }
    fetchData()
    
  },[initiateDatatable, navigate, code])

  const statusBadge = (status) => {
    if(status==='success') return <span className="badge badge-success capitalize">{status}</span>
    else if(status==='process') return <span className="badge badge-primary capitalize">{status}</span>
    else if(status==='pending') return <span className="badge badge-warning capitalize">{status}</span>
    else if(status==='fail') return <span className="badge badge-danger capitalize">${status}</span>
    else return <span className="badge badge-default capitalize">{status}</span>
  }

  const tableRow = (rowData, i) =>{
    return (
      <tr key={i}>
        <td>{rowData.bank.name}</td>
        <td>{rowData.account_number}</td>
        <td>{rowData.account_name}</td>
        <td>{rowData.nominal}</td>
        <td>{rowData.fee||0}</td>
        <td>{statusBadge(rowData.status)}</td>
        <td>{formatDate(rowData.date_created)}</td>
      </tr>
    )
  }

  return (
    <>
      {loadingScreen && <LoadingComponent />}    
      <div className="card">
        <div className="card-body">
          <div> 
            <div className="table-responsive">
              <table className="table table-hover data-table">
                <thead>
                  <tr>
                    <th>Bank Name</th>
                    <th>Account Number</th>
                    <th>Account Name</th>
                    <th>Amount</th>
                    <th>Transaction Fee</th>
                    <th>Status</th>
                    <th>Date</th> 
                  </tr>
                </thead>
                <tbody>
                  {
                    detailData.map((trData,i) => {
                      return tableRow(trData,i)
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default AdminTransactionDetail