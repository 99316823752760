import React, { useEffect, useState, useRef, useCallback } from "react";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet, fetchPost } from "../../../utils/API";
import { toast } from "../../../utils/Helper";
import { useNavigate } from "react-router-dom";
import OTP from "../../../components/OTP";
import LoadingComponent from "../../../components/LoadingComponent";

const AdminProfile = () => {
  let navigate = useNavigate();
  const userOTPRef = useRef();
  const passwordOTPRef = useRef();
  const companyOTPRef = useRef();

  const [businessSection, setBusinessSection] = useState([]);
  const [provinces, setProvince] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [subDistricts, setSubdistrict] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [isPassMatch, setIsPassMatch] = useState(null);

  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone_number: "",
    occupation: "",
  });
  const [userField, setUserField] = useState({
    name: "",
    email: "",
    phone_number: "",
    occupation: "",
  });
  const [passwordField, setPasswordField] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [companyInfo, setCompanyInfo] = useState({
    company_name: "",
    company_email: "",
    company_street: "",
    company_subdistrict: "",
    company_district: "",
    company_city: "",
    company_province: "",
    company_postal_code: "",
    company_country: "Indonesia",
    company_domain: "",
    company_business_section: "",
  });
  const [companyField, setCompanyField] = useState({
    company_name: "",
    company_email: "",
    company_street: "",
    company_subdistrict: "",
    company_district: "",
    company_city: "",
    company_province: "",
    company_postal_code: "",
    company_country: "Indonesia",
    company_domain: "",
    company_business_section: "",
  });

  const userInputsHandler = (e) => {
    const { name, value } = e.target;
    setUserField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const passwordInputsHandler = (e) => {
    const { name, value } = e.target;
    setPasswordField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "confirm_password") {
      setIsPassMatch(passwordField.new_password === value ? true : false);
    }
  };

  const companyInputsHandler = (e) => {
    const { name, value } = e.target;
    setCompanyField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onProvinceChange = async (e) => {
    let val = e.target.value;
    setCompanyField((prevState) => ({
      ...prevState,
      company_province: val,
    }));
    let dataCity = await fetchGet(`${API_PATH.CITY_LIST}/${val}`);
    if (dataCity.success) setCities(dataCity.data);
  };

  const onCityChange = async (e) => {
    let val = e.target.value;
    setCompanyField((prevState) => ({
      ...prevState,
      company_city: val,
    }));
    let dataDistrict = await fetchGet(`${API_PATH.DISTRICT_LIST}/${val}`);
    if (dataDistrict.success) setDistrict(dataDistrict.data);
  };

  const onDistrictChange = async (e) => {
    let val = e.target.value;
    setCompanyField((prevState) => ({
      ...prevState,
      company_district: val,
    }));
    let dataSubdistrict = await fetchGet(`${API_PATH.SUBDISTRICT_LIST}/${val}`);
    if (dataSubdistrict.success) setSubdistrict(dataSubdistrict.data);
  };

  const updateUserSubmit = useCallback(async () => {
    var res = await fetchPost(API_PATH.UPDATE_USER_PROFILE, userField);
    toast(res.message, res.success);
    if (res.success) {
      userOTPRef.current.handleOTPDialog("END");
      setUserInfo(userField);
    }
  }, [userField]);

  const updatePasswordSubmit = useCallback(async () => {
    var res = await fetchPost(API_PATH.CHANGE_PASSWORD, {
      old_password: passwordField.old_password,
      new_password: passwordField.new_password,
    });
    toast(res.message, res.success);
    if (res.success) {
      setIsPassMatch(null);
      setPasswordField({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
      passwordOTPRef.current.handleOTPDialog("END");
    }
  }, [passwordField]);

  const updateCompanySubmit = useCallback(async () => {
    var res = await fetchPost(API_PATH.UPDATE_COMPANY_PROFILE, companyField);
    toast(res.message, res.success);
    if (res.success) {
      setCompanyInfo(companyField);
      companyOTPRef.current.handleOTPDialog("END");
    }
  }, [companyField]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingScreen(true);
      try {
        const [dataProf, dataBS, dataProv] = await Promise.all([
          fetchGet(API_PATH.PROFILE),
          fetchGet(API_PATH.BUSINESS_SECTION_LIST),
          fetchGet(API_PATH.PROVINCE_LIST),
        ]);

        if (!dataProf.success) {
          toast(dataProf.message, dataProf.success);
          if (dataProf.status === 401)
            navigate("/signin", { state: { ...dataProf } });
        } else if (!dataBS.success) {
          toast(dataBS.message, dataBS.success);
          if (dataBS.status === 401)
            navigate("/signin", { state: { ...dataBS } });
        } else if (!dataProv.success) {
          toast(dataProv.message, dataProv.success);
          if (dataProv.status === 401)
            navigate("/signin", { state: { ...dataProv } });
        }

        setBusinessSection(dataBS.data);
        setProvince(dataProv.data);

        let userData = {
          name: dataProf.data.name,
          email: dataProf.data.email,
          phone_number: dataProf.data.phone_number,
          occupation: dataProf.data.occupation,
        };
        setUserField(userData);
        setUserInfo(userData);
        if (dataProf.data.company !== null) {
          let companyData = {
            company_name: dataProf.data.company.name,
            company_email: dataProf.data.company.email,
            company_street: dataProf.data.company.street,
            company_subdistrict: dataProf.data.company.subdistrict,
            company_district: dataProf.data.company.district,
            company_city: dataProf.data.company.city,
            company_province: dataProf.data.company.province,
            company_postal_code: dataProf.data.company.postal_code,
            company_country: dataProf.data.company.country,
            company_domain: dataProf.data.company.domain,
            company_business_section: dataProf.data.company.business_section,
          };
          setCompanyField(companyData);
          setCompanyInfo(companyData);
          const [dataCity, dataDist, dataSubdis] = await Promise.all([
            fetchGet(`${API_PATH.CITY_LIST}/${dataProf.data.company.province}`),
            fetchGet(`${API_PATH.DISTRICT_LIST}/${dataProf.data.company.city}`),
            fetchGet(
              `${API_PATH.SUBDISTRICT_LIST}/${dataProf.data.company.district}`
            ),
          ]);

          setCities(dataCity.data);
          setDistrict(dataDist.data);
          setSubdistrict(dataSubdis.data);
        }
      } catch (e) {
        toast(e, false);
      }

      setLoadingScreen(false);
    };
    fetchData();
  }, [navigate]);

  const renderTabAccount = () => {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">User Infomation</h4>
          </div>
          <div className="card-body">
            <div className="media align-items-center">
              <div className="avatar avatar-icon avatar-blue avatar-lg">
                <i className="anticon anticon-user"></i>
              </div>
              <div className="m-l-20 m-r-20">
                <h5 className="m-b-5 font-size-18">{userInfo.name}</h5>
                <p className="opacity-07 font-size-13 m-b-0">
                  Mail: {userInfo.email}
                  <br />
                  <span>Phone: {userInfo.phone_number}</span>
                </p>
              </div>
            </div>
            <hr className="m-v-25" />
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="name">
                  Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  onChange={userInputsHandler}
                  placeholder="Name"
                  value={userField.name}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="email">
                  Email:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="email"
                  onChange={userInputsHandler}
                  placeholder="Email"
                  value={userField.email}
                  required
                />
              </div>
              <div className="form-group col-md-3 ">
                <label className="font-weight-semibold" htmlFor="phone_number">
                  Phone:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="phone_number"
                  id="phone_number"
                  onChange={userInputsHandler}
                  placeholder="Phone"
                  value={userField.phone_number}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="occupation">
                  Occupation/Job:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="occupation"
                  id="occupation"
                  onChange={userInputsHandler}
                  placeholder="Occupation/Job"
                  value={userField.occupation}
                  required
                />
              </div>
              <div className="form-group col-md-4 ">
                <button
                  className="btn btn-primary m-t-30"
                  onClick={() => userOTPRef.current.handleOTPDialog("START")}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Change Password</h4>
          </div>
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="old_password">
                  Old Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="old_password"
                  id="old_password"
                  onChange={passwordInputsHandler}
                  placeholder="Old Password"
                  value={passwordField.old_password}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="new_password">
                  New Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="new_password"
                  id="new_password"
                  onChange={passwordInputsHandler}
                  placeholder="New Password"
                  value={passwordField.new_password}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="font-weight-semibold"
                  htmlFor="confirm_password"
                >
                  Confirm Password:
                </label>
                <input
                  type="password"
                  className={
                    isPassMatch === null
                      ? "form-control"
                      : isPassMatch
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  name="confirm_password"
                  id="confirm_password"
                  onChange={passwordInputsHandler}
                  placeholder="Confirm Password"
                  value={passwordField.confirm_password}
                  required
                />
                {isPassMatch !== null && (
                  <div
                    className={
                      (isPassMatch ? "valid" : "invalid") + "-feedback"
                    }
                  >
                    {isPassMatch ? "Match" : "Not Match"}
                  </div>
                )}
              </div>
              <div className="form-group col-md-3">
                <button
                  className="btn btn-primary m-t-30"
                  onClick={() =>
                    passwordOTPRef.current.handleOTPDialog("START")
                  }
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTabCompany = () => {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Company Infomation</h4>
          </div>
          <div className="card-body">
            <div className="media align-items-center">
              <div className="avatar avatar-icon avatar-blue avatar-lg">
                <i className="anticon anticon-bank"></i>
              </div>
              <div className="m-l-20 m-r-20">
                <h5 className="m-b-5 font-size-18">
                  {companyInfo.company_name}
                </h5>
                <p className="opacity-07 font-size-13 m-b-0">
                  Mail: {companyInfo.company_email}
                  <br />
                  <span>
                    Address:{" "}
                    {companyInfo.company_street +
                      ", " +
                      companyInfo.company_city +
                      ", " +
                      companyInfo.company_state +
                      ", " +
                      companyInfo.company_country +
                      ", " +
                      companyInfo.company_postal_code}
                  </span>
                </p>
              </div>
            </div>
            <hr className="m-v-25" />
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="company_name">
                  Company Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_name"
                  id="company_name"
                  onChange={companyInputsHandler}
                  placeholder="Name"
                  value={companyField.company_name}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="company_email">
                  Company Email:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_email"
                  id="company_email"
                  onChange={companyInputsHandler}
                  placeholder="Email"
                  value={companyField.company_email}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card-header">
            <h4 className="card-title">Address Detail</h4>
          </div>
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-4">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_country"
                >
                  Company Country:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_country"
                  id="company_country"
                  onChange={companyInputsHandler}
                  placeholder="Country"
                  value={companyField.company_country}
                  readOnly
                  required
                />
              </div>
              <div className="form-group col-md-4">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_province"
                >
                  Company Province:
                </label>
                <select
                  className="custom-select"
                  name="company_province"
                  value={companyField.company_province}
                  onChange={onProvinceChange}
                >
                  <option>Choose...</option>
                  {provinces.map((data, i) => (
                    <option key={i} value={data.prov_id}>
                      {data.prov_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-4">
                <label className="font-weight-semibold" htmlFor="company_city">
                  Company City:
                </label>
                <select
                  className="custom-select"
                  name="company_city"
                  value={companyField.company_city}
                  onChange={onCityChange}
                >
                  <option>Choose...</option>
                  {cities.map((data, i) => (
                    <option key={i} value={data.city_id}>
                      {data.city_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-3">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_district"
                >
                  Company District:
                </label>
                <select
                  className="custom-select"
                  name="company_district"
                  value={companyField.company_district}
                  onChange={onDistrictChange}
                >
                  <option>Choose...</option>
                  {districts.map((data, i) => (
                    <option key={i} value={data.dis_id}>
                      {data.dis_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-3">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_subdistrict"
                >
                  Company Subdistrict:
                </label>
                <select
                  className="custom-select"
                  name="company_subdistrict"
                  value={companyField.company_subdistrict}
                  onChange={companyInputsHandler}
                >
                  <option>Choose...</option>
                  {subDistricts.map((data, i) => (
                    <option key={i} value={data.subdis_id}>
                      {data.subdis_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_street"
                >
                  Company Address:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_street"
                  id="company_street"
                  onChange={companyInputsHandler}
                  placeholder="Address"
                  value={companyField.company_street}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_postal_code"
                >
                  Company Postal Code:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_postal_code"
                  id="company_postal_code"
                  onChange={companyInputsHandler}
                  placeholder="Postal Code"
                  value={companyField.company_postal_code}
                  required
                />
              </div>
              <div className="form-group col-md-4">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_domain"
                >
                  Company Domain:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_domain"
                  id="company_domain"
                  onChange={companyInputsHandler}
                  placeholder="Company Domain"
                  value={companyField.company_domain}
                  required
                />
              </div>
              <div className="form-group col-md-5">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_business_section"
                >
                  Company Business Section:
                </label>
                <select
                  className="custom-select"
                  name="company_business_section"
                  value={companyField.company_business_section}
                  onChange={companyInputsHandler}
                >
                  <option>Choose...</option>
                  {businessSection.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-3">
                <button
                  className="btn btn-primary m-t-30"
                  onClick={() => companyOTPRef.current.handleOTPDialog("START")}
                  // onClick={updateCompanySubmit}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div>
        <div className="page-header has-tab">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#tab-account"
              >
                Account
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#tab-company">
                Company
              </a>
            </li>
          </ul>
        </div>
        <div className="">
          <div className="tab-content m-t-15">
            <div className="tab-pane fade show active" id="tab-account">
              {renderTabAccount()}
            </div>
            <div className="tab-pane fade" id="tab-company">
              {renderTabCompany()}
            </div>
          </div>
        </div>
        {/* OTP here */}
        <OTP
          ref={userOTPRef}
          id="update_profie"
          onSubmitPressCallback={updateUserSubmit}
        />
        <OTP
          ref={passwordOTPRef}
          id="update_password"
          onSubmitPressCallback={updatePasswordSubmit}
        />
        <OTP
          ref={companyOTPRef}
          id="update_company"
          onSubmitPressCallback={updateCompanySubmit}
        />
      </div>
    </>
  );
};

export default AdminProfile;
