import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet } from "../../../utils/API";

function Verify() {
  const { code } = useParams();
  const navigate = useNavigate();

  // const inputsHandler = (e) => {
  //   const { name, value } = e.target;
  //   setInputField((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const submitButton = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   var res = await fetchGet(`${API_PATH.SUBMIT_VERIFY}/code`);
  //   toast(res.message, res.success);
  //   setLoading(false);
  //   if (res.success) {
  //     setTimeout(function () {
  //       navigate("/signin");
  //     }, 3000);
  //   }
  // };
  const onSubmit = useCallback(async () => {
    if (code) {
      var res = await fetchGet(`${API_PATH.SUBMIT_VERIFY}/${code}`);
      toast(res.message, res.success);
      if (res.success) {
        setTimeout(function () {
          navigate("/signin");
        }, 2000);
      } else {
        navigate("/404", { replace: true });
      }
    }
  }, [code, navigate]);

  useEffect(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    // <div className="row w-100 m-0 align-items-center">
    //   <div className="col-md-7 col-lg-5 m-h-auto">
    //     <div className="card shadow-lg">
    //       <div className="card-body">
    //         <div className="d-flex align-items-center justify-content-between m-b-30">
    //           <img
    //             className="img-fluid"
    //             alt=""
    //             src="assets/images/logo/logo.png"
    //           />
    //           <h3 className="m-b-0">Verify</h3>
    //         </div>
    //         <form>
    //           <div className="d-flex align-items-center justify-content">
    //             Please code that have been sent to your email to verify
    //           </div>
    //           <div className="form-group">
    //             <label className="font-weight-semibold" htmlFor="userName">
    //               Code:
    //             </label>
    //             <div className="input-affix">
    //               <i className="prefix-icon anticon anticon-code" />
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 name="code"
    //                 id="code"
    //                 onChange={inputsHandler}
    //                 placeholder="Code"
    //                 value={inputField.code}
    //               />
    //             </div>
    //           </div>
    //           <div className="form-group">
    //             <div className="d-flex align-items-center justify-content-between">
    //               <button
    //                 className={[
    //                   "btn btn-primary ",
    //                   loading ? " is-loading" : "",
    //                 ].join(" ")}
    //                 onClick={submitButton}
    //               >
    //                 <i class="anticon anticon-loading m-r-5"></i>
    //                 <span>Submit</span>
    //               </button>
    //             </div>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div />
  );
}

export default Verify;
