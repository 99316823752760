import React, { useLayoutEffect, useState } from "react";
import { fetchGet } from "../../../../utils/API";
import {
  formatDate,
  initiateDatatable,
  toGlobalCurrency,
  toast,
} from "../../../../utils/Helper";
import { Link, useNavigate } from "react-router-dom";
import { API_PATH } from "../../../../utils/Constant";

const WalletTabInOut = () => {
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState([
    { transaction_details: [], user: {} },
  ]);

  const statusBadge = (status) => {
    if (status === "success")
      return <span className="badge badge-success capitalize">{status}</span>;
    else if (status === "process")
      return <span className="badge badge-primary capitalize">{status}</span>;
    else if (status === "pending")
      return <span className="badge badge-warning capitalize">{status}</span>;
    else if (status === "canceled" || status === "failed")
      return <span className="badge badge-danger capitalize">{status}</span>;
    else
      return <span className="badge badge-default capitalize">{status}</span>;
  };

  const tableRow = (rowData, i) => {
    return (
      <tr key={i}>
        <td>
          {rowData.currencies?.symbol} ({rowData.currencies?.code})
        </td>
        <td>{toGlobalCurrency(rowData.total_nominal || 0)}</td>
        <td>{toGlobalCurrency(rowData.total_fee || 0)}</td>
        <td>{statusBadge(rowData.type)}</td>
        <td>{statusBadge(rowData.status)}</td>
        <td>{formatDate(rowData.date_created)}</td>
        <td>{rowData.user.name}</td>
        <td>
          <Link to={"/transaction/" + rowData.code}>
            <button
              className="btn btn-icon btn-primary btn-rounded btn-tone m-t-5 m-r-5"
              data-tip
              data-for={"deleteRow-" + i}
              data-event="click"
            >
              <i className="anticon anticon-arrow-right"></i>
            </button>
          </Link>
        </td>
      </tr>
    );
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      var res = await fetchGet(API_PATH.TRANSACTION, { type: "withdraw" });
      if (!res.success) {
        toast(res.message, res.success);
        if (res.status === 401) navigate("/signin", { state: { ...res } });
      } else {
        setTransactionData(res.data || []);
        document.getElementById("DataTables_Table_0_wrapper")?.remove();
        // initiateDatatable({
        //   currencyCols: [2, 3],
        //   filterTextCols: [0, 1, 2, 3, 6],
        //   filterSelectCols: [4, 5],
        //   filterDateCols: [6],
        // });
      }
    };
    fetchData();
  }, [initiateDatatable, navigate]);
  return (
    <table className="table table-responsive table-hover data-table">
      <thead>
        <tr>
          <th>Currency</th>
          <th>Total Amount</th>
          <th>Transaction Fee</th>
          <th>Type</th>
          <th>Status</th>
          <th>Date</th>
          <th>Created By</th>
          <th>Detail</th>
        </tr>
      </thead>
      <tbody>
        {transactionData.map((trData, i) => {
          return tableRow(trData, i);
        })}
      </tbody>
    </table>
  );
};

export default WalletTabInOut;
