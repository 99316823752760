import React, { useLayoutEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { initiateDatatable, toast, formatDate, initiateDatepicker } from '../../../utils/Helper'
import { API_PATH } from '../../../utils/Constant'
import { fetchGet } from '../../../utils/API'
import LoadingComponent from '../../../components/LoadingComponent'

const AdminLogs = () => {
  const navigate = useNavigate()
  const [logData, setLogData] = useState([{user: {},company: {}}]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  useLayoutEffect( () => {
    const fetchData = async () => {
      setLoadingScreen(true)
      var res = await fetchGet(API_PATH.LOGS)
      if(!res.success) {
        toast(res.message,res.success)
        if(res.status===401) navigate('/signin', { state: { ...res } })
      } else {
        setLogData(res.data)
        initiateDatatable({
          currencyCols:[],
          filterTextCols:[1,3,4,5,6,7],
          filterSelectCols:[2],
          filterDateCols:[0]
        })
        initiateDatepicker()
      }
      setLoadingScreen(false)
    }
    fetchData()
    
  },[initiateDatatable, navigate])

  const tableRow = (rowData, i) =>{
    let company = rowData.company !== null ? rowData.company.name : '-'
    return (
      <tr key={i}>
        <td>{formatDate(rowData.date_created)}</td>
        <td>{rowData.title}</td> 
        <td>{rowData.type}</td>
        <td>{rowData.description}</td>
        <td>{rowData.ip_address}</td>
        <td>{rowData.device_information}</td>
        <td>{company}</td>
        <td>{rowData.user?.name}</td>
      </tr>
    )
  }

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div className="card">
        <div className="card-body">    
          <div> 
            <div className="table-responsive">
              <table className="table table-hover data-table ">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>IP Address</th>
                    <th>Device</th> 
                    <th>Company</th> 
                    <th>User</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    logData.map((trData,i) => {
                      return tableRow(trData,i)
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminLogs