import React from "react";
import { Link } from "react-router-dom";
import { useMain } from "../config/context/MainContext";
import { getPath } from "../utils/Helper";

import sidebar_items from "../config/sidebar_routes.json";

const SidebarItem = (props) => {
  const activeItem = props.active ? "active" : "";
  // console.log(props.val.path, props.active, activeItem)
  return (
    <li className={`nav-item dropdown ${activeItem}`}>
      {!("routes" in props.val) ? (
        <Link to={props.val.path}>
          <span className="icon-holder">
            <i className={props.val.icon} />
          </span>
          <span className="title">{props.val.name}</span>
        </Link>
      ) : (
        <>
          <a hrefLang="javascript:void(0)" className="dropdown-toggle" to={""}>
            <span className="icon-holder">
              <i className={props.val.icon} />
            </span>
            <span className="title">{props.val.name}</span>
            <span className="arrow">
              <i className="arrow-icon" />
            </span>
          </a>
          <ul className="dropdown-menu">
            {props.val.routes.map((item, index) => (
              <li key={index}>
                <Link to={item.path}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

const Sidebar = () => {
  const { user } = useMain();
  const path = getPath();
  console.log("path", path);
  if (user.role === "super-admin") path.shift(); //get current route

  var activeItem = sidebar_items[user.role].findIndex((item) => {
    if (item.multi_path) {
      return item.multi_path?.includes(path[path.length - 1]);
    }
    return (
      item.path.split("/")[item.path.split("/").length - 1] ===
      path[path.length - 1]
    );
  });

  // if(user.role === 'admin')
  // var activeItem = sidebar_items.admin
  // if(user.role === 'super-admin')
  //   activeItem = sidebar_items['super-admin'].findIndex(item => item.path.split('/')[item.path.split('/').length-1] === path[path.length-1])
  // console.log(path,activeItem,path[path.length-1],user.role,sidebar_items['super-admin'].map(item => item.path.split('/')[item.path.split('/').length-1]))

  return (
    <div className="side-nav">
      <div className="side-nav-inner">
        <ul className="side-nav-menu scrollable">
          {user.role === "personal" &&
            sidebar_items.personal.map((item, index) => (
              <SidebarItem
                key={index}
                val={item}
                active={index === activeItem}
              />
            ))}
          {user.role === "admin" &&
            sidebar_items.admin.map((item, index) => (
              <SidebarItem
                key={index}
                val={item}
                active={index === activeItem}
              />
            ))}
          {user.role === "super-admin" &&
            sidebar_items["super-admin"].map((item, index) => (
              <SidebarItem
                key={index}
                val={item}
                active={index === activeItem}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
