import React, { useState, useEffect, useCallback } from "react";
import { toast, hashPassword } from "../../../utils/Helper";
import { useNavigate } from "react-router-dom";
import { API_PATH } from "../../../utils/Constant";
import { fetchPost, fetchGet } from "../../../utils/API";

const businessDefaultFields = {
  company_name: "",
  company_email: "",
  company_street: "",
  company_subdistrict: "",
  company_district: "",
  company_city: "",
  company_province: "",
  company_postal_code: "",
  company_country: "",
  company_domain: "",
  company_business_section: "",
};

const businessRequiredFieldsIndo = [
  "name",
  "email",
  "phone_number",
  "occupation",
  "password",
  "confirm_password",
  "company_name",
  "company_email",
  "company_street",
  "company_subdistrict",
  "company_district",
  "company_city",
  "company_province",
  "company_postal_code",
  "company_country",
  "company_domain",
  "company_business_section",
  // Add other required field names here
];
const businessRequiredFields = [
  "name",
  "email",
  "phone_number",
  "occupation",
  "password",
  "confirm_password",
  "company_name",
  "company_email",
  "company_street",
  "company_postal_code",
  "company_country",
  "company_domain",
  "company_business_section",
  // Add other required field names here
];

const personalRequiredFields = [
  "name",
  "email",
  "phone_number",
  "occupation",
  "password",
  "confirm_password",
  // Add other required field names here
];

function Signup() {
  let navigate = useNavigate();
  const [isIndonesia, setIsIndonesia] = useState(true);
  const [businessSection, setBusinessSection] = useState([]);
  const [countries, setCountry] = useState([]);
  const [provinces, setProvince] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [subDistricts, setSubdistrict] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isPassMatch, setIsPassMatch] = useState(null);
  const [isPersonal, setIsPersonal] = useState(true);
  const [inputField, setInputField] = useState({
    name: "",
    email: "",
    phone_number: "",
    occupation: "",
    password: "",
    confirm_password: "",
    ...businessDefaultFields,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dataBS, dataProv, dataCountry] = await Promise.all([
          fetchGet(API_PATH.BUSINESS_SECTION_LIST),
          fetchGet(API_PATH.PROVINCE_LIST),
          fetchGet(API_PATH.COUNTRY_LIST),
        ]);
        setBusinessSection(dataBS.data);
        setProvince(dataProv.data);
        setCountry(dataCountry.data);
      } catch (e) {
        toast(e, false);
      }
    };
    fetchData();
  }, []);

  const validateForm = useCallback(
    (checked) => {
      let isNotEmptyData = null;
      if (isPersonal) {
        isNotEmptyData = personalRequiredFields.every((field) => {
          const value = inputField[field];
          if (value !== null && value !== "") {
            return true;
          }
          return false;
        });
      } else if (isIndonesia) {
        isNotEmptyData = businessRequiredFieldsIndo.every((field) => {
          const value = inputField[field];
          if (value !== null && value !== "") {
            return true;
          }
          return false;
        });
      } else {
        isNotEmptyData = businessRequiredFields.every((field) => {
          const value = inputField[field];
          if (value !== null && value !== "") {
            return true;
          }
          return false;
        });
      }
      // const isNotEmptyData = Object.values(inputField).every(([key, value]) => {
      //   if (isRequiredField(key)) {
      //     if (value !== null && value !== "") {
      //       return true;
      //     }
      //     return false;
      //   }
      //   return true;
      // });
      setDisabled(!isNotEmptyData && checked);
    },
    [inputField, isIndonesia, isPersonal]
  );

  const inputsHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      if (name === "confirm_password") {
        setIsPassMatch(inputField.password === value ? true : false);
      }
    },
    [inputField.password]
  );
  const onCountryChange = async (e) => {
    let val = e.target.value;
    setInputField((prevState) => ({
      ...prevState,
      company_country: val,
    }));
    if (val === "100") {
      setIsIndonesia(true);
    } else {
      setIsIndonesia(false);
    }
  };
  const onProvinceChange = async (e) => {
    let val = e.target.value;
    setInputField((prevState) => ({
      ...prevState,
      company_province: val,
    }));
    let dataCity = await fetchGet(`${API_PATH.CITY_LIST}/${val}`);
    if (dataCity.success) setCities(dataCity.data);
  };

  const onCityChange = async (e) => {
    let val = e.target.value;
    setInputField((prevState) => ({
      ...prevState,
      company_city: val,
    }));
    let dataDistrict = await fetchGet(`${API_PATH.DISTRICT_LIST}/${val}`);
    if (dataDistrict.success) {
      setDistrict(dataDistrict.data);
    }
  };

  const onDistrictChange = async (e) => {
    let val = e.target.value;
    setInputField((prevState) => ({
      ...prevState,
      company_district: val,
    }));
    let dataSubdistrict = await fetchGet(`${API_PATH.SUBDISTRICT_LIST}/${val}`);
    if (dataSubdistrict.success) setSubdistrict(dataSubdistrict.data);
  };

  const submitButton = async (e) => {
    e.preventDefault();
    setLoading(true);
    const hashedPassword = hashPassword(inputField.password);

    let data = { ...inputField };
    data.password = hashedPassword;
    const registerURL = isPersonal ? API_PATH.SIGNUP_PERSONAL : API_PATH.SIGNUP;
    var res = await fetchPost(registerURL, data);
    let message = res.message;
    if (!res.success && res.data) {
      message = Object.values(res.data)
        .map((item) => item.message)
        .join(", ");
    }
    toast(message, res.success);
    setLoading(false);
    if (res.success) {
      setTimeout(function () {
        navigate("/signin");
      }, 1000);
    }
  };

  function isRequiredField(key) {
    // Add your logic to determine if a field is required
    // You can use a predefined list of required field names or any other condition
    // For example, you can check if the field name ends with "*"
    return key.endsWith("*");
  }

  useEffect(() => {
    let isNotEmptyData = null;
    if (isPersonal) {
      isNotEmptyData = personalRequiredFields.every((field) => {
        const value = inputField[field];
        if (value !== null && value !== "") {
          return true;
        }
        return false;
      });
    } else if (isIndonesia) {
      isNotEmptyData = businessRequiredFieldsIndo.every((field) => {
        const value = inputField[field];
        if (value !== null && value !== "") {
          return true;
        }
        return false;
      });
    } else {
      isNotEmptyData = businessRequiredFields.every((field) => {
        const value = inputField[field];
        if (value !== null && value !== "") {
          return true;
        }
        return false;
      });
    }
    // const isNotEmptyData = Object.values(inputField).every(([key, value]) => {
    //   if (isRequiredField(key)) {
    //     if (value !== null && value !== "") {
    //       return true;
    //     }
    //     return false;
    //   }
    //   return true;
    // });
    setDisabled(!(isNotEmptyData && checked));
  }, [checked, inputField, isIndonesia, isPersonal]);

  return (
    <div className="row w-100 m-0 align-items-center">
      <div className="col-md-10 m-h-auto">
        <div className="card shadow-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between m-b-30">
              <img
                className="img-fluid"
                alt=""
                src="assets/images/logo/logo.png"
              />
              <h3 className="m-b-0">Sign Up</h3>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="font-weight-semibold" htmlFor="password">
                    Type:
                  </label>
                  <div className="tw-flex tw-items-center tw-gap-6 tw-mt-2">
                    <div className="checkbox">
                      <input
                        id="isPersonal"
                        type="checkbox"
                        name="isPersonal"
                        checked={isPersonal}
                        onChange={(e) => {
                          setIsPersonal(true);
                          setInputField((prevState) => ({
                            ...prevState,
                            ...businessDefaultFields,
                          }));
                        }}
                      />
                      <label htmlFor="isPersonal">
                        <span>Personal</span>
                      </label>
                    </div>
                    <div className="checkbox">
                      <input
                        id="isBusiness"
                        type="checkbox"
                        name="isBusiness"
                        checked={!isPersonal}
                        onChange={(e) => {
                          setIsPersonal(false);
                        }}
                      />
                      <label htmlFor="isBusiness">
                        <span>Business</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="font-weight-semibold" htmlFor="name">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    onChange={inputsHandler}
                    placeholder="Name"
                    value={inputField.name}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-semibold" htmlFor="email">
                    Email:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    onChange={inputsHandler}
                    placeholder="Email"
                    value={inputField.email}
                    required
                  />
                </div>
                <div className="form-group">
                  <label
                    className="font-weight-semibold"
                    htmlFor="phone_number"
                  >
                    Phone:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone_number"
                    id="phone_number"
                    onChange={inputsHandler}
                    placeholder="Phone"
                    value={inputField.phone_number}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-semibold" htmlFor="occupation">
                    Occupation/Job:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="occupation"
                    id="occupation"
                    onChange={inputsHandler}
                    placeholder="Occupation/Job"
                    value={inputField.occupation}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-semibold" htmlFor="password">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    onChange={inputsHandler}
                    placeholder="Password"
                    value={inputField.password}
                    required
                  />
                </div>
                <div className="form-group">
                  <label
                    className="font-weight-semibold"
                    htmlFor="confirm_password"
                  >
                    Confirm Password:
                  </label>
                  <input
                    type="password"
                    className={
                      isPassMatch === null
                        ? "form-control"
                        : isPassMatch
                        ? "form-control is-valid"
                        : "form-control is-invalid"
                    }
                    name="confirm_password"
                    id="confirm_password"
                    onChange={inputsHandler}
                    placeholder="Confirm Password"
                    value={inputField.confirm_password}
                    required
                  />
                  {isPassMatch !== null && (
                    <div
                      className={
                        (isPassMatch ? "valid" : "invalid") + "-feedback"
                      }
                    >
                      {isPassMatch ? "Match" : "Not Match"}
                    </div>
                  )}
                </div>
                {isPersonal && (
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between p-t-15">
                      <div className="checkbox">
                        <input
                          id="confirmCheck"
                          type="checkbox"
                          name="confirmCheck"
                          checked={checked}
                          onChange={(e) => {
                            setChecked(!checked);
                            validateForm(e.target.checked);
                          }}
                        />
                        <label htmlFor="confirmCheck">
                          <span>I have read the agreement</span>
                        </label>
                      </div>
                      <button
                        className={[
                          "btn btn-primary ",
                          loading ? " is-loading" : "",
                        ].join(" ")}
                        onClick={submitButton}
                        disabled={disabled}
                      >
                        <i class="anticon anticon-loading m-r-5"></i>
                        <span>Sign Up</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {!isPersonal && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="font-weight-semibold"
                      htmlFor="company_name"
                    >
                      Company Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_name"
                      id="company_name"
                      onChange={inputsHandler}
                      placeholder="Name"
                      value={inputField.company_name}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-semibold"
                      htmlFor="company_email"
                    >
                      Company Email:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_email"
                      id="company_email"
                      onChange={inputsHandler}
                      placeholder="Email"
                      value={inputField.company_email}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-semibold"
                      htmlFor="company_country"
                    >
                      Company Country:
                    </label>
                    <select
                      className="custom-select"
                      name="company_country"
                      value={inputField.company_country}
                      onChange={onCountryChange}
                    >
                      <option>Choose...</option>
                      {countries.map((data, i) => (
                        <option key={i} value={data.id}>
                          {data.nicename}
                        </option>
                      ))}
                    </select>
                  </div>
                  {isIndonesia && (
                    <div className="form-group">
                      <label
                        className="font-weight-semibold"
                        htmlFor="company_province"
                      >
                        Company Province:
                      </label>
                      <select
                        className="custom-select"
                        name="company_province"
                        value={inputField.company_province}
                        onChange={onProvinceChange}
                      >
                        <option>Choose...</option>
                        {provinces.map((data, i) => (
                          <option key={i} value={data.prov_id}>
                            {data.prov_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {isIndonesia && (
                    <div className="form-group">
                      <label
                        className="font-weight-semibold"
                        htmlFor="company_city"
                      >
                        Company City:
                      </label>
                      <select
                        className="custom-select"
                        name="company_city"
                        value={inputField.company_city}
                        onChange={onCityChange}
                      >
                        <option>Choose...</option>
                        {cities.map((data, i) => (
                          <option key={i} value={data.city_id}>
                            {data.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {isIndonesia && (
                    <div className="form-group">
                      <label
                        className="font-weight-semibold"
                        htmlFor="company_district"
                      >
                        Company District:
                      </label>
                      <select
                        className="custom-select"
                        name="company_district"
                        value={inputField.company_district}
                        onChange={onDistrictChange}
                      >
                        <option>Choose...</option>
                        {districts.map((data, i) => (
                          <option key={i} value={data.dis_id}>
                            {data.dis_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {isIndonesia && (
                    <div className="form-group">
                      <label
                        className="font-weight-semibold"
                        htmlFor="company_subdistrict"
                      >
                        Company Subdistrict:
                      </label>
                      <select
                        className="custom-select"
                        name="company_subdistrict"
                        value={inputField.company_subdistrict}
                        onChange={inputsHandler}
                      >
                        <option>Choose...</option>
                        {subDistricts.map((data, i) => (
                          <option key={i} value={data.subdis_id}>
                            {data.subdis_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="form-group">
                    <label
                      className="font-weight-semibold"
                      htmlFor="company_street"
                    >
                      Company Address:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_street"
                      id="company_street"
                      onChange={inputsHandler}
                      placeholder="Address"
                      value={inputField.company_street}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-semibold"
                      htmlFor="company_postal_code"
                    >
                      Company Postal Code:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_postal_code"
                      id="company_postal_code"
                      onChange={inputsHandler}
                      placeholder="Postal Code"
                      value={inputField.company_postal_code}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-semibold"
                      htmlFor="company_domain"
                    >
                      Company Domain:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_domain"
                      id="company_domain"
                      onChange={inputsHandler}
                      placeholder="Domain"
                      value={inputField.company_domain}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-semibold"
                      htmlFor="company_business_section"
                    >
                      Company Business Section:
                    </label>
                    <select
                      className="custom-select"
                      name="company_business_section"
                      value={inputField.company_business_section}
                      onChange={inputsHandler}
                    >
                      <option>Choose...</option>
                      {businessSection.map((data, i) => (
                        <option key={i} value={data.id}>
                          {data.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between p-t-15">
                      <div className="checkbox">
                        <input
                          id="confirmCheck"
                          type="checkbox"
                          name="confirmCheck"
                          checked={checked}
                          onChange={(e) => {
                            setChecked(!checked);
                            validateForm(e.target.checked);
                          }}
                        />
                        <label htmlFor="confirmCheck">
                          <span>I have read the agreement</span>
                        </label>
                      </div>
                      <button
                        className={[
                          "btn btn-primary ",
                          loading ? " is-loading" : "",
                        ].join(" ")}
                        onClick={submitButton}
                        disabled={disabled}
                      >
                        <i class="anticon anticon-loading m-r-5"></i>
                        <span>Sign Up</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
