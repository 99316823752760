import React, { useEffect, useState } from "react";
import { fetchGet } from "../../../../utils/API";
import {
  formatDate,
  initiateDatatable,
  toGlobalCurrency,
  toast,
} from "../../../../utils/Helper";
import { useNavigate } from "react-router-dom";
import { API_PATH } from "../../../../utils/Constant";

const WalletHistory = () => {
  const [walletData, setWalletData] = useState([]);
  const [initiateTable, setInitiateTable] = useState(true);

  const navigate = useNavigate();

  const typeBadge = (data) => {
    if (data === "in")
      return (
        <span className="badge badge-pill badge-green capitalize">{data}</span>
      );
    else
      return (
        <span className="badge badge-pill badge-red capitalize">{data}</span>
      );
  };

  const categoryBadge = (data) => {
    if (data === "deposit")
      return <span className="badge badge-blue capitalize">{data}</span>;
    else if (data === "refund")
      return <span className="badge badge-geekblue capitalize">{data}</span>;
    else if (data === "disburse")
      return <span className="badge badge-orange capitalize">{data}</span>;
    else if (data === "withdraw")
      return <span className="badge badge-volcano capitalize">{data}</span>;
    else return <span className="badge badge-cyan capitalize">{data}</span>;
  };

  const tableRow = (rowData, i) => {
    return (
      <tr key={i}>
        <td>
          <span style={{ textTransform: "uppercase" }}>#WLIO{rowData.id}</span>
        </td>
        <td>{toGlobalCurrency(rowData.nominal)}</td>
        <td>{toGlobalCurrency(rowData.current_total)}</td>
        <td>
          {rowData.currencies?.symbol} ({rowData.currencies?.code})
        </td>
        <td>{typeBadge(rowData.type)}</td>
        <td>{categoryBadge(rowData.category || "dummy")}</td>
        <td>{formatDate(rowData.date_created)}</td>
      </tr>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      var res = await fetchGet(API_PATH.WALLET);
      if (!res.success) {
        toast(res.message, res.success);
        if (res.status === 401) navigate("/signin", { state: { ...res } });
      } else {
        setWalletData(res.data.inOutList || []);
        document.getElementById("DataTables_Table_0_wrapper")?.remove();
        // initiateDatatable({
        //   currencyCols: [1, 2],
        //   filterTextCols: [0, 1, 2],
        //   filterSelectCols: [3, 4],
        //   filterDateCols: [5],
        // });
      }
    };
    fetchData();
  }, [initiateTable, navigate, walletData.length]);
  return (
    <table className="table-responsive table table-hover data-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Amount</th>
          <th>Current Total</th>
          <th>Currency</th>
          <th>Type</th>
          <th>Category</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {walletData.map((trData, i) => {
          return tableRow(trData, i);
        })}
      </tbody>
    </table>
  );
};

export default WalletHistory;
