import Axios from "axios";
import secureLocalStorage from "react-secure-storage";
// import { API_PATH } from './Constant'

export const APIURL = process.env.REACT_APP_API_URL; //isDevelopment ? "http://localhost:4000/api" : 'https://k8s.apidev.motra.co.id'

const basicheader = { "Content-type": "application/json; charset=UTF-8" };
export const fetchGet = async (endpoint, params) => {
  const requestOptions = {
    headers: {
      ...basicheader,
      Authorization: secureLocalStorage.getItem("token"),
    },
    params,
  };
  try {
    const response = await Axios.get(APIURL + endpoint, requestOptions);
    var resdata = response.data;
  } catch (e) {
    console.log(`😱 Fetch request failed: ${e.response.data.message}`);
    return {
      success: false,
      message: e.response.data.message,
      status: e.response.status,
    };
  }

  if (resdata.success) {
    return resdata;
  } else {
    return resdata;
  }
};

export const fetchPost = async (endpoint, data) => {
  const requestOptions = {
    headers: {
      ...basicheader,
      Authorization: secureLocalStorage.getItem("token"),
    },
  };
  try {
    const response = await Axios.post(APIURL + endpoint, data, requestOptions);
    var resdata = response.data;
  } catch (e) {
    console.log(`😱 Fetch request failed: ${e.response.data.message}`);
    return {
      success: false,
      message: e.response.data.message,
      status: e.response.data.status,
    };
  }

  if (resdata.success) {
    return resdata;
  } else {
    return resdata;
  }
};

export const fetchPostFormData = async (endpoint, data) => {
  const requestOptions = {
    headers: {
      ...basicheader,
      "Content-Type": "multipart/form-data",
      Authorization: secureLocalStorage.getItem("token"),
    },
  };
  try {
    const response = await Axios.post(APIURL + endpoint, data, requestOptions);
    var resdata = response.data;
  } catch (e) {
    console.log(`😱 Fetch request failed: ${e.response.data.message}`);
    return {
      success: false,
      message: e.response.data.message,
      status: e.response.data.status,
    };
  }

  if (resdata.success) {
    return resdata;
  } else {
    return resdata;
  }
};

export const fetchDelete = async (endpoint) => {
  const requestOptions = {
    headers: {
      ...basicheader,
      Authorization: secureLocalStorage.getItem("token"),
    },
  };
  try {
    const response = await Axios.delete(APIURL + endpoint, requestOptions);
    var resdata = response.data;
  } catch (e) {
    console.log(`😱 Fetch request failed: ${e.response.data.message}`);
    return {
      success: false,
      message: e.response.data.message,
      status: e.response.data.status,
    };
  }

  if (resdata.success) {
    return resdata;
  } else {
    return resdata;
  }
};

export const fetchPut = async (endpoint, data) => {
  const requestOptions = {
    headers: {
      ...basicheader,
      Authorization: secureLocalStorage.getItem("token"),
    },
  };
  try {
    const response = await Axios.put(APIURL + endpoint, data, requestOptions);
    var resdata = response.data;
  } catch (e) {
    console.log(`😱 Fetch request failed: ${e.response.data.message}`);
    return {
      success: false,
      message: e.response.data.message,
      status: e.response.data.status,
    };
  }

  if (resdata.success) {
    return resdata;
  } else {
    return resdata;
  }
};

// const refreshTokenRequest = async () => {
//   var res = await fetchGet(API_PATH.REFRESH_TOKEN)
//   if(!res.success) {
//     toast(res.message, res.status)
//     return false
//   } else {
//     console.log(res.data)
//     return true
//   }
// }
