import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import moment from "moment";
import numeral from "numeral";

export const formatDate = (value) => {
  return moment(value).format("MM/DD/YYYY HH:mm:ss");
};

export const formatCurrency = (value) => {
  value = typeof value != "undefined" ? value : 0;
  return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
};

export const toGlobalCurrency = (value) => {
  return numeral(value).format(`0,0`);
};

export const stringCurrency = (value) => {
  value = typeof value != "undefined" ? value : 0;
  if (value / 100000000 > 1) {
    return (value / 1000000000).toLocaleString("pt-BR") + " M";
  } else if (value / 100000 > 1) {
    return (value / 1000000).toLocaleString("pt-BR") + " Jt";
  } else {
    return (value / 1000).toLocaleString("pt-BR") + " Rb";
  }
};

export const toast = (message, success) => {
  window["showToast"](message, success);
};

export const initiateDatatable = (options) => {
  window["initiateDatatable"](options);
};

export const initiateDatepicker = () => {
  window["initiateDatepicker"]();
};

export const toggleModal = (id) => {
  window["toggleModal"](id);
};

export const getPath = () => {
  let path = window.location.pathname.split("/");
  path.shift();
  return path;
};

export const getTitle = () => {
  let path = getPath();
  let title = path[path.length - 1].split("_").map((p) => capitalize(p));
  return path[path.length - 1] === "" ? "Dashboard" : title.join(" ");
};

export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const hashPassword = (pass) => {
  let hashDigest = sha256(pass);
  return Base64.stringify(hashDigest);
};
