import React, { useEffect, useState, useRef, useCallback } from "react";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet, fetchPost } from "../../../utils/API";
import { toast, formatDate, toggleModal } from "../../../utils/Helper";
import { useNavigate } from "react-router-dom";
import OTP from "../../../components/OTP";
import ReactTooltip from "react-tooltip";
import RightDrawer from "../../../components/RightDrawer";
import LoadingComponent from "../../../components/LoadingComponent";
import { useMain } from "../../../config/context/MainContext";

const Profile = () => {
  let navigate = useNavigate();
  const userOTPRef = useRef();
  const passwordOTPRef = useRef();
  const APITokenOTPRef = useRef();
  const SBAPITokenOTPRef = useRef();
  const companyOTPRef = useRef();

  const { user } = useMain();
  const [loading, setLoading] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [isPassMatch, setIsPassMatch] = useState(null);
  const [apiToken, setApiToken] = useState("");
  const [apiPassword, setApiPassword] = useState("");
  const [sbApiToken, setSBApiToken] = useState("");
  const [sbApiPassword, setSBApiPassword] = useState("");
  const [whitelistIP, setWhitelistIP] = useState([]);
  const [newIP, setNewIP] = useState("");
  const [disabledIPFormButton, setDisabledIPFormButton] = useState(true);
  const [bankAccount, setBankAccount] = useState([
    { bank: {}, created_by: {} },
  ]);
  const [disabledBankFormButton, setDisabledBankFormButton] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [businessSection, setBusinessSection] = useState([]);
  const [isIndonesia, setIsIndonesia] = useState(true);
  const [countries, setCountry] = useState([]);
  const [provinces, setProvince] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [subDistricts, setSubdistrict] = useState([]);

  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone_number: "",
    occupation: "",
    isAPIProdActive: null,
  });
  const [userField, setUserField] = useState({
    name: "",
    email: "",
    phone_number: "",
    occupation: "",
  });
  const [passwordField, setPasswordField] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [companyInfo, setCompanyInfo] = useState({
    company_name: "",
    company_email: "",
    company_street: "",
    company_subdistrict: "",
    company_district: "",
    company_city: "",
    company_province: "",
    company_postal_code: "",
    company_country: "Indonesia",
    company_domain: "",
    company_business_section: "",
  });
  const [companyField, setCompanyField] = useState({
    company_name: "",
    company_email: "",
    company_street: "",
    company_subdistrict: "",
    company_district: "",
    company_city: "",
    company_province: "",
    company_postal_code: "",
    company_country: "",
    company_domain: "",
    company_business_section: "",
  });
  const [createBankData, setCreateBankData] = useState({
    bank_id: "",
    account_name: "",
    account_number: "",
    currency_code: "",
  });
  const [updateBankData, setUpdateBankData] = useState({
    bank_id: "",
    account_name: "",
    account_number: "",
    bank_account_id: "",
    currency_id: "",
  });
  const requiredFieldsIndo = [
    "company_subdistrict",
    "company_district",
    "company_city",
    "company_province",
  ];

  const userInputsHandler = (e) => {
    const { name, value } = e.target;
    setUserField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const passwordInputsHandler = (e) => {
    const { name, value } = e.target;
    setPasswordField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "confirm_password") {
      setIsPassMatch(passwordField.new_password === value ? true : false);
    }
  };

  const companyInputsHandler = (e) => {
    const { name, value } = e.target;
    setCompanyField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCountryChange = async (e) => {
    let val = e.target.value;
    setCompanyField((prevState) => ({
      ...prevState,
      company_country: val,
      company_province: "",
      company_city: "",
      company_district: "",
      company_subdistrict: "",
    }));
    if (val === "100") {
      setIsIndonesia(true);
    } else {
      setIsIndonesia(false);
    }
  };

  const onProvinceChange = async (e) => {
    let val = e.target.value;
    setCompanyField((prevState) => ({
      ...prevState,
      company_province: val,
      company_city: "",
      company_district: "",
      company_subdistrict: "",
    }));
    let dataCity = await fetchGet(`${API_PATH.CITY_LIST}/${val}`);
    if (dataCity.success) setCities(dataCity.data);
  };

  const onCityChange = async (e) => {
    let val = e.target.value;
    setCompanyField((prevState) => ({
      ...prevState,
      company_city: val,
      company_district: "",
      company_subdistrict: "",
    }));
    let dataDistrict = await fetchGet(`${API_PATH.DISTRICT_LIST}/${val}`);
    if (dataDistrict.success) setDistrict(dataDistrict.data);
  };

  const onDistrictChange = async (e) => {
    let val = e.target.value;
    setCompanyField((prevState) => ({
      ...prevState,
      company_district: val,
      company_subdistrict: "",
    }));
    let dataSubdistrict = await fetchGet(`${API_PATH.SUBDISTRICT_LIST}/${val}`);
    if (dataSubdistrict.success) setSubdistrict(dataSubdistrict.data);
  };

  const updateUserSubmit = useCallback(async () => {
    var res = await fetchPost(API_PATH.UPDATE_USER_PROFILE, userField);
    toast(res.message, res.success);
    if (res.success) {
      userOTPRef.current.handleOTPDialog("END");
      setUserInfo(userField);
    }
  }, [userField]);

  const updatePasswordSubmit = useCallback(async () => {
    var res = await fetchPost(API_PATH.CHANGE_PASSWORD, {
      old_password: passwordField.old_password,
      new_password: passwordField.new_password,
    });
    toast(res.message, res.success);
    if (res.success) {
      setIsPassMatch(null);
      setPasswordField({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
      passwordOTPRef.current.handleOTPDialog("END");
    }
  }, [passwordField]);

  const updateCompanySubmit = useCallback(async () => {
    console.log(companyField);
    var res = await fetchPost(API_PATH.UPDATE_COMPANY_PROFILE, companyField);
    toast(res.message, res.success);
    if (res.success) {
      setCompanyInfo(companyField);
      companyOTPRef.current.handleOTPDialog("END");
    }
  }, [companyField]);

  const refreshAPIAuthSubmit = useCallback(async () => {
    var res = await fetchGet(API_PATH.REFRESH_API_TOKEN);
    toast(res.message, res.success);
    if (res.success) {
      setApiToken(res.data.new_key);
      setApiPassword(res.data.new_password);
      APITokenOTPRef.current.handleOTPDialog("END");
    }
  }, []);

  const refreshSBAPIAuthSubmit = useCallback(async () => {
    var res = await fetchGet(API_PATH.REFRESH_SB_API_TOKEN);
    toast(res.message, res.success);
    if (res.success) {
      setSBApiToken(res.data.new_key);
      setSBApiPassword(res.data.new_password);
      SBAPITokenOTPRef.current.handleOTPDialog("END");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingScreen(true);

      try {
        const [dataProf, dataBS, dataProv, dataCountry, dataWIP, dataAcc] =
          await Promise.all([
            fetchGet(API_PATH.PROFILE),
            fetchGet(API_PATH.BUSINESS_SECTION_LIST),
            fetchGet(API_PATH.PROVINCE_LIST),
            fetchGet(API_PATH.COUNTRY_LIST),
            fetchGet(API_PATH.WHITELIST_IP),
            fetchGet(API_PATH.BANK_ACCOUNT),
          ]);

        if (!dataProf.success) {
          toast(dataProf.message, dataProf.success);
          if (dataProf.status === 401)
            navigate("/signin", { state: { ...dataProf } });
        } else if (!dataBS.success) {
          toast(dataBS.message, dataBS.success);
          if (dataBS.status === 401)
            navigate("/signin", { state: { ...dataBS } });
        } else if (!dataProv.success) {
          toast(dataProv.message, dataProv.success);
          if (dataProv.status === 401)
            navigate("/signin", { state: { ...dataProv } });
        } else if (!dataWIP.success) {
          toast(dataWIP.message, dataWIP.success);
        } else if (!dataAcc.success) {
          toast(dataAcc.message, dataAcc.success);
        }

        setBusinessSection(dataBS?.data || []);
        setCountry(dataCountry?.data || []);
        setProvince(dataProv?.data || []);
        setWhitelistIP(dataWIP?.data || []);
        setBankAccount(dataAcc?.data || []);
        // setBankList(dataBank.data);

        setApiToken(dataProf.data.api_token);
        setApiPassword(dataProf.data.api_password);
        setSBApiToken(dataProf.data.sb_api_token);
        setSBApiPassword(dataProf.data.sb_api_password);
        let userData = {
          name: dataProf.data.name,
          email: dataProf.data.email,
          phone_number: dataProf.data.phone_number,
          occupation: dataProf.data.occupation,
          isAPIProdActive: dataProf.data.is_api_prod_active,
        };
        setUserField(userData);
        setUserInfo(userData);
        if (dataProf.data.company !== null) {
          let companyData = {
            company_name: dataProf.data.company.name,
            company_email: dataProf.data.company.email,
            company_street: dataProf.data.company.street,
            company_subdistrict: dataProf.data.company?.subdistrict || "",
            company_district: dataProf.data.company?.district || "",
            company_city: dataProf.data.company?.city || "",
            company_province: dataProf.data.company?.province || "",
            company_postal_code: dataProf.data.company.postal_code,
            company_country: dataProf.data.company?.country || "",
            company_domain: dataProf.data.company.domain,
            company_business_section: dataProf.data.company.business_section,
          };
          setCompanyField(companyData);
          setCompanyInfo(companyData);
          if (dataProf.data.company?.country === "100") {
            setIsIndonesia(true);
          } else {
            setIsIndonesia(false);
          }
        }

        const [dataCity, dataDist, dataSubdis] = await Promise.all([
          fetchGet(`${API_PATH.CITY_LIST}/${dataProf.data.company.province}`),
          fetchGet(`${API_PATH.DISTRICT_LIST}/${dataProf.data.company.city}`),
          fetchGet(
            `${API_PATH.SUBDISTRICT_LIST}/${dataProf.data.company.district}`
          ),
        ]);

        setCities(dataCity?.data || []);
        setDistrict(dataDist?.data || []);
        setSubdistrict(dataSubdis?.data || []);
      } catch (e) {
        toast(e, false);
      }
      setLoadingScreen(false);
    };
    fetchData();
  }, [navigate]);

  const fetchCurrencies = useCallback(async () => {
    const res = await fetchGet(API_PATH.CURRENCIES);
    if (!res.success) {
      toast(res.message, res.success);
      if (res.status === 401) {
        navigate("/signin", { state: { ...res } });
      }
    } else {
      setCurrencies(res.data || []);
    }
  }, [navigate]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const onDeleteIP = async (id) => {
    var res = await fetchPost(API_PATH.DELETE_WHITELIST, { whitelist_id: id });
    if (res.success) {
      setWhitelistIP(whitelistIP.filter((dt) => dt.id !== id));
    }
    toast(res.message, res.success);
    ReactTooltip.hide();
  };

  const onCreateIP = async () => {
    setLoading(true);
    var res = await fetchPost(API_PATH.CREATE_WHITELIST, { ip_address: newIP });
    if (res.success) {
      setWhitelistIP([...whitelistIP, res.data]);
      toggleModal(`#create_ip_form`);
      setNewIP("");
    }
    toast(res.message, res.success);
    setLoading(false);
  };

  const onDeleteBankAccount = async (id) => {
    var res = await fetchPost(API_PATH.DELETE_BANK_ACCOUNT, {
      bank_account_id: id,
    });
    if (res.success) {
      setBankAccount(bankAccount.filter((dt) => dt.id !== id));
    }
    toast(res.message, res.success);
    ReactTooltip.hide();
  };

  const onCreateBankAccount = async () => {
    setLoading(true);
    var res = await fetchPost(API_PATH.CREATE_BANK_ACCOUNT, createBankData);
    if (res.success) {
      var bank = bankList.find(
        (dt) => parseInt(createBankData.bank_id) === dt.id
      );
      var baTemp = [...bankAccount, { ...res.data, bank: bank }];
      setBankAccount(baTemp);
      toggleModal(`#create_bank_form`);
      setCreateBankData({
        bank_id: "",
        account_name: "",
        account_number: "",
        currency_code: "",
      });
    }
    toast(res.message, res.success);
    setLoading(false);
  };

  const onUpdateBankAccount = async () => {
    setLoading(true);
    const { currency_id, ...restPayload } = updateBankData;
    const currencyCode = currencies.find(
      (item) => item.id === currency_id
    )?.symbol;

    const payload = { ...restPayload, currency_code: currencyCode };
    var res = await fetchPost(API_PATH.UPDATE_BANK_ACCOUNT, payload);
    if (res.success) {
      const dataAcc = await fetchGet(API_PATH.BANK_ACCOUNT);
      if (!dataAcc.success) {
        toast(dataAcc.message, dataAcc.success);
      }
      setBankAccount(dataAcc?.data || bankAccount);
      toggleModal(`#update_bank_form`);
      setUpdateBankData({
        bank_id: "",
        account_name: "",
        account_number: "",
        bank_account_id: "",
        currency_id: "",
      });
    }
    toast(res.message, res.success);
    setLoading(false);
  };

  const validateCompanyForm = () => {
    if (isIndonesia) {
      const isNotEmptyData = requiredFieldsIndo.some(
        (item) => companyField[item] !== null && companyField[item] !== ""
      );
      if (!isNotEmptyData) {
        alert("Harap lengkapi field data alamat terlebih dahulu");
        return;
      }
    }

    companyOTPRef.current.handleOTPDialog("START");
  };

  const validateForm = (type, name, value) => {
    let data =
      type === "create" ? { ...createBankData } : { ...updateBankData };
    data[name] = value;
    if (isIndonesia) {
      const isNotEmptyData = requiredFieldsIndo.every((value) => {
        if (value !== null && value !== "") {
          return true;
        }
        return false;
      });
      setDisabledBankFormButton(!isNotEmptyData);
      return;
    }

    const isNotEmptyData = Object.values(data).every((value) => {
      if (value !== null && value !== "") {
        return true;
      }
      return false;
    });
    setDisabledBankFormButton(!isNotEmptyData);
  };

  const fetchBanksByCurrencyId = async (currencyId) => {
    const res = await fetchGet(`${API_PATH.BANK_LIST}/${currencyId}`);
    if (!res.success) {
      toast(res.message, res.success);
      if (res.status === 401) {
        navigate("/signin", { state: { ...res } });
      }
    } else {
      setBankList(res.data || []);
    }
  };

  const bankCreateAccountInputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === "currency_code") {
      const currencyId = currencies.find((item) => item.symbol === value)?.id;
      fetchBanksByCurrencyId(currencyId);
      setCreateBankData((prevState) => ({
        ...prevState,
        [name]: value,
        bank_id: "",
      }));
      validateForm("create", name, value);
      validateForm("create", "bank_id", "");
    } else {
      setCreateBankData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      validateForm("create", name, value);
    }
  };

  const bankUpdateAccountInputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === "currency_id") {
      fetchBanksByCurrencyId(value);
      setUpdateBankData((prevState) => ({
        ...prevState,
        [name]: value,
        bank_id: "",
      }));
      validateForm("update", name, value);
      validateForm("update", "bank_id", "");
    } else {
      setUpdateBankData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      validateForm("update", name, value);
    }
  };

  const renderDeleteTooltipContent = (id, type) => {
    return (
      <>
        <p style={{ display: "block" }}>
          Are you sure want to delete this data?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {type === "ip" ? (
            <button
              className="btn btn-default btn-xs m-r-5"
              onClick={() => onDeleteIP(id)}
            >
              Yes
            </button>
          ) : (
            <button
              className="btn btn-default btn-xs m-r-5"
              onClick={() => onDeleteBankAccount(id)}
            >
              Yes
            </button>
          )}
          <button
            className="btn btn-default btn-xs"
            onClick={() => ReactTooltip.hide()}
          >
            No
          </button>
        </div>
      </>
    );
  };

  const renderTableIPRow = (data, i) => {
    return (
      <tr key={i}>
        <td>{data.ip_address}</td>
        <td>{formatDate(data.date_created)}</td>
        <td>
          <button
            className="btn btn-icon btn-danger btn-rounded btn-tone m-t-5 m-r-5"
            data-tip
            data-for={"deleteRow-" + i}
            data-event="click"
          >
            <i className="anticon anticon-delete"></i>
          </button>
          <ReactTooltip
            id={"deleteRow-" + i}
            place="top"
            effect="solid"
            backgroundColor="#ffbebe"
            clickable={true}
          >
            {renderDeleteTooltipContent(data.id, "ip")}
          </ReactTooltip>
        </td>
      </tr>
    );
  };

  const renderTableBankRow = (data, i) => {
    return (
      <tr key={i}>
        <td>{data.bank.name}</td>
        <td>{data.account_number}</td>
        <td>{data.account_name}</td>
        <td>{formatDate(data.date_created)}</td>
        <td>
          <button
            className="btn btn-icon btn-danger btn-rounded btn-tone m-t-5 m-r-5"
            data-tip
            data-for={"deleteRowBank-" + i}
            data-event="click"
          >
            <i className="anticon anticon-delete"></i>
          </button>
          <ReactTooltip
            id={"deleteRowBank-" + i}
            place="top"
            effect="solid"
            backgroundColor="#ffbebe"
            clickable={true}
          >
            {renderDeleteTooltipContent(data.id, "bank")}
          </ReactTooltip>
          <button
            className="btn btn-icon btn-primary btn-rounded btn-tone m-t-5 m-r-5"
            data-toggle="modal"
            data-target="#update_bank_form"
            onClick={() => {
              setUpdateBankData({
                bank_id: data.bank.id,
                account_name: data.account_name,
                account_number: data.account_number,
                bank_account_id: data.id,
                currency_id: data.bank?.currency_id,
              });
              fetchBanksByCurrencyId(data.bank?.currency_id);
            }}
          >
            <i className="anticon anticon-edit"></i>
          </button>
        </td>
      </tr>
    );
  };

  const renderTabAccount = () => {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">User Infomation</h4>
          </div>
          <div className="card-body">
            <div className="media align-items-center">
              <div className="avatar avatar-icon avatar-blue avatar-lg">
                <i className="anticon anticon-user"></i>
              </div>
              <div className="m-l-20 m-r-20">
                <h5 className="m-b-5 font-size-18">{userInfo.name}</h5>
                <p className="opacity-07 font-size-13 m-b-0">
                  Mail: {userInfo.email}
                  <br />
                  <span>Phone: {userInfo.phone_number}</span>
                </p>
              </div>
            </div>
            <hr className="m-v-25" />
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="name">
                  Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  onChange={userInputsHandler}
                  placeholder="Name"
                  value={userField.name}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="email">
                  Email:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="email"
                  onChange={userInputsHandler}
                  placeholder="Email"
                  value={userField.email}
                  required
                />
              </div>
              <div className="form-group col-md-3 ">
                <label className="font-weight-semibold" htmlFor="phone_number">
                  Phone:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="phone_number"
                  id="phone_number"
                  onChange={userInputsHandler}
                  placeholder="Phone"
                  value={userField.phone_number}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="occupation">
                  Occupation/Job:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="occupation"
                  id="occupation"
                  onChange={userInputsHandler}
                  placeholder="Occupation/Job"
                  value={userField.occupation}
                  required
                />
              </div>
              <div className="form-group col-md-4 ">
                <button
                  className="btn btn-primary m-t-30"
                  onClick={() => userOTPRef.current.handleOTPDialog("START")}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Change Password</h4>
          </div>
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="old_password">
                  Old Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="old_password"
                  id="old_password"
                  onChange={passwordInputsHandler}
                  placeholder="Old Password"
                  value={passwordField.old_password}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="new_password">
                  New Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  name="new_password"
                  id="new_password"
                  onChange={passwordInputsHandler}
                  placeholder="New Password"
                  value={passwordField.new_password}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="font-weight-semibold"
                  htmlFor="confirm_password"
                >
                  Confirm Password:
                </label>
                <input
                  type="password"
                  className={
                    isPassMatch === null
                      ? "form-control"
                      : isPassMatch
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  name="confirm_password"
                  id="confirm_password"
                  onChange={passwordInputsHandler}
                  placeholder="Confirm Password"
                  value={passwordField.confirm_password}
                  required
                />
                {isPassMatch !== null && (
                  <div
                    className={
                      (isPassMatch ? "valid" : "invalid") + "-feedback"
                    }
                  >
                    {isPassMatch ? "Match" : "Not Match"}
                  </div>
                )}
              </div>
              <div className="form-group col-md-3">
                <button
                  className="btn btn-primary m-t-30"
                  onClick={() =>
                    passwordOTPRef.current.handleOTPDialog("START")
                  }
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
        {user.role === "admin" && (
          <div className="card page-header has-tab">
            <div className="card-header">
              <h4 className="card-title">API Token</h4>
            </div>
            {typeof userInfo.isAPIProdActive === "boolean" && (
              <div className="card-body">
                <ul className="nav nav-tabs -tw-mt-4">
                  {[
                    ...(!userInfo.isAPIProdActive
                      ? [
                          {
                            type: "api-sandbox",
                            name: "Sandbox",
                          },
                        ]
                      : []),
                    {
                      type: "api-production",
                      name: "Production",
                    },
                  ].map((item, idx) => (
                    <li key={`nav-tab-api-${idx}`} className={`nav-item`}>
                      <a
                        className={`nav-link ${idx === 0 ? "active" : ""}`}
                        data-toggle="tab"
                        href={`#${item.type}`}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <div className="tab-content tw-mt-4">
                  {[
                    ...(!userInfo.isAPIProdActive
                      ? [
                          {
                            type: "api-sandbox",
                            apiToken: sbApiToken,
                            apiPassword: sbApiPassword,
                            onSubmit: () =>
                              SBAPITokenOTPRef.current.handleOTPDialog("START"),
                            isEnabled: true,
                          },
                        ]
                      : []),
                    {
                      type: "api-production",
                      apiToken: apiToken,
                      apiPassword: apiPassword,
                      onSubmit: () =>
                        APITokenOTPRef.current.handleOTPDialog("START"),
                      isEnabled: userInfo.isAPIProdActive,
                    },
                  ].map((item, idx) => {
                    return (
                      <div
                        id={item.type}
                        className={`tw-px-4 tab-pane fade ${
                          idx === 0 ? "show active" : ""
                        }`}
                      >
                        {item.isEnabled ? (
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label
                                className="font-weight-semibold"
                                htmlFor="api_token"
                              >
                                API Token:
                              </label>
                              <input
                                type="text"
                                className="form-control tw-cursor-pointer"
                                name="api_token"
                                id="api_token"
                                placeholder="Api Key"
                                value={item.apiToken}
                                readOnly
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label
                                className="font-weight-semibold"
                                htmlFor="api_password"
                              >
                                API Password:
                              </label>
                              <input
                                type="text"
                                className="form-control tw-cursor-pointer"
                                name="api_password"
                                id="api_password"
                                placeholder="Api Password"
                                value={item.apiPassword}
                                readOnly
                              />
                            </div>
                            <div className="form-group col-md-3 ">
                              <button
                                className="btn btn-primary m-t-30"
                                onClick={item.onSubmit}
                              >
                                Request Update Key
                              </button>
                            </div>
                          </div>
                        ) : (
                          <p>
                            To use Production Key please contact the{" "}
                            <b>Administrator</b> first
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const renderTabCompany = () => {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Company Infomation</h4>
          </div>
          <div className="card-body">
            <div className="media align-items-center">
              <div className="avatar avatar-icon avatar-blue avatar-lg">
                <i className="anticon anticon-bank"></i>
              </div>
              <div className="m-l-20 m-r-20">
                <h5 className="m-b-5 font-size-18">
                  {companyInfo.company_name}
                </h5>
                <p className="opacity-07 font-size-13 m-b-0">
                  Mail: {companyInfo.company_email}
                  <br />
                  <span>
                    Address:{" "}
                    {companyInfo.company_street +
                      ", " +
                      companyInfo.company_city +
                      ", " +
                      companyInfo.company_state +
                      ", " +
                      companyInfo.company_country +
                      ", " +
                      companyInfo.company_postal_code}
                  </span>
                </p>
              </div>
            </div>
            <hr className="m-v-25" />
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="company_name">
                  Company Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_name"
                  id="company_name"
                  onChange={companyInputsHandler}
                  placeholder="Name"
                  value={companyField.company_name}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label className="font-weight-semibold" htmlFor="company_email">
                  Company Email:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_email"
                  id="company_email"
                  onChange={companyInputsHandler}
                  placeholder="Email"
                  value={companyField.company_email}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card-header">
            <h4 className="card-title">Address Detail</h4>
          </div>
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-4">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_country"
                >
                  Company Country:
                </label>
                <select
                  className="custom-select"
                  name="company_country"
                  value={companyField.company_country}
                  onChange={onCountryChange}
                >
                  <option>Choose...</option>
                  {countries.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.nicename}
                    </option>
                  ))}
                </select>
                {/* <input 
                    type="text" 
                    className="form-control"
                    name="company_country" 
                    id="company_country"
                    onChange={companyInputsHandler} 
                    placeholder="Country" 
                    value={companyField.company_country}
                    readOnly
                    required/> */}
              </div>
              {isIndonesia && (
                <div className="form-group col-md-4">
                  <label
                    className="font-weight-semibold"
                    htmlFor="company_province"
                  >
                    Company Province:
                  </label>
                  <select
                    className="custom-select"
                    name="company_province"
                    value={companyField.company_province}
                    onChange={onProvinceChange}
                  >
                    <option>Choose...</option>
                    {provinces.map((data, i) => (
                      <option key={i} value={data.prov_id}>
                        {data.prov_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {isIndonesia && (
                <div className="form-group col-md-4">
                  <label
                    className="font-weight-semibold"
                    htmlFor="company_city"
                  >
                    Company City:
                  </label>
                  <select
                    className="custom-select"
                    name="company_city"
                    value={companyField.company_city}
                    onChange={onCityChange}
                  >
                    <option>Choose...</option>
                    {cities.map((data, i) => (
                      <option key={i} value={data.city_id}>
                        {data.city_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {isIndonesia && (
                <div className="form-group col-md-3">
                  <label
                    className="font-weight-semibold"
                    htmlFor="company_district"
                  >
                    Company District:
                  </label>
                  <select
                    className="custom-select"
                    name="company_district"
                    value={companyField.company_district}
                    onChange={onDistrictChange}
                  >
                    <option>Choose...</option>
                    {districts.map((data, i) => (
                      <option key={i} value={data.dis_id}>
                        {data.dis_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {isIndonesia && (
                <div className="form-group col-md-3">
                  <label
                    className="font-weight-semibold"
                    htmlFor="company_subdistrict"
                  >
                    Company Subdistrict:
                  </label>
                  <select
                    className="custom-select"
                    name="company_subdistrict"
                    value={companyField.company_subdistrict}
                    onChange={companyInputsHandler}
                  >
                    <option>Choose...</option>
                    {subDistricts.map((data, i) => (
                      <option key={i} value={data.subdis_id}>
                        {data.subdis_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="form-group col-md-6">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_street"
                >
                  Company Address:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_street"
                  id="company_street"
                  onChange={companyInputsHandler}
                  placeholder="Address"
                  value={companyField.company_street}
                  required
                />
              </div>
              <div className="form-group col-md-3">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_postal_code"
                >
                  Company Postal Code:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_postal_code"
                  id="company_postal_code"
                  onChange={companyInputsHandler}
                  placeholder="Postal Code"
                  value={companyField.company_postal_code}
                  required
                />
              </div>
              <div className="form-group col-md-4">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_domain"
                >
                  Company Domain:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="company_domain"
                  id="company_domain"
                  onChange={companyInputsHandler}
                  placeholder="Company Domain"
                  value={companyField.company_domain}
                  required
                />
              </div>
              <div className="form-group col-md-5">
                <label
                  className="font-weight-semibold"
                  htmlFor="company_business_section"
                >
                  Company Business Section:
                </label>
                <select
                  className="custom-select"
                  name="company_business_section"
                  value={companyField.company_business_section}
                  onChange={companyInputsHandler}
                >
                  <option>Choose...</option>
                  {businessSection.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-3">
                <button
                  className="btn btn-primary m-t-30"
                  onClick={validateCompanyForm}
                  // onClick={updateCompanySubmit}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTabSetting = () => {
    return (
      <>
        {user.role === "admin" && (
          <div className="card">
            <div className="card-header">
              <div className="d-md-flex m-b-15 align-items-center justify-content-between">
                <h4 className="card-title">Whitelist IPs</h4>
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#create_ip_form"
                >
                  <span>Add New</span>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th>IP Address</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {whitelistIP.map((data, i) => {
                      return renderTableIPRow(data, i);
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className="card">
          <div className="card-header">
            <div className="d-md-flex m-b-15 align-items-center justify-content-between">
              <h4 className="card-title">Bank Account</h4>
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#create_bank_form"
              >
                <span>Add New</span>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover table-sm ">
                <thead>
                  <tr>
                    <th>Bank Name</th>
                    <th>Account Number</th>
                    <th>Account Name</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {bankAccount.map((data, i) => {
                    return renderTableBankRow(data, i);
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div>
        <div className="page-header has-tab">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#tab-account"
              >
                Account
              </a>
            </li>
            {user.role === "admin" && (
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#tab-company">
                  Company
                </a>
              </li>
            )}

            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#tab-setting">
                Setting
              </a>
            </li>
          </ul>
        </div>
        <div className="">
          <div className="tab-content m-t-15">
            <div className="tab-pane fade show active" id="tab-account">
              {renderTabAccount()}
            </div>
            {user.role === "admin" && (
              <div className="tab-pane fade" id="tab-company">
                {renderTabCompany()}
              </div>
            )}

            <div className="tab-pane fade" id="tab-setting">
              {renderTabSetting()}
            </div>
          </div>
        </div>
        {/* OTP here */}
        <OTP
          ref={userOTPRef}
          id="update_profie"
          onSubmitPressCallback={updateUserSubmit}
        />
        <OTP
          ref={passwordOTPRef}
          id="update_password"
          onSubmitPressCallback={updatePasswordSubmit}
        />
        <OTP
          ref={APITokenOTPRef}
          id="refresh_api_key"
          onSubmitPressCallback={refreshAPIAuthSubmit}
        />
        <OTP
          ref={SBAPITokenOTPRef}
          id="refresh_sb_api_key"
          onSubmitPressCallback={refreshSBAPIAuthSubmit}
        />
        <OTP
          ref={companyOTPRef}
          id="update_company"
          onSubmitPressCallback={updateCompanySubmit}
        />

        {/* create delete form  */}
        <RightDrawer id="create_ip_form" title="Add Whitelist IP">
          <div>
            <div>
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="ip_address">
                  IP Address:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="ip_address"
                  id="ip_address"
                  onChange={(e) => {
                    setNewIP(e.target.value);
                    setDisabledIPFormButton(
                      e.target.value === "" ? true : false
                    );
                  }}
                  placeholder="IP Address"
                  value={newIP}
                  required
                />
              </div>
              <div className="form-group">
                <button
                  className={[
                    "btn btn-primary ",
                    loading ? " is-loading" : "",
                  ].join(" ")}
                  onClick={onCreateIP}
                  disabled={disabledIPFormButton}
                >
                  <i className="anticon anticon-loading m-r-5"></i>
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </RightDrawer>
        <RightDrawer id="create_bank_form" title="Add Bank Account">
          <div>
            <div>
              <div className="form-group">
                <label htmlFor="bank_account">Currency</label>
                <select
                  className="custom-select"
                  name="currency_code"
                  value={createBankData.currency_code}
                  onChange={bankCreateAccountInputsHandler}
                >
                  <option disabled value="">
                    Choose...
                  </option>
                  {currencies.map((data, i) => (
                    <option key={i} value={data.symbol}>
                      {data.symbol} ({data.code})
                    </option>
                  ))}
                </select>
              </div>
              {createBankData.currency_code && (
                <div className="form-group">
                  <label htmlFor="bank_account">Bank Account</label>
                  <select
                    className="custom-select"
                    name="bank_id"
                    value={createBankData.bank_id}
                    onChange={bankCreateAccountInputsHandler}
                  >
                    <option disabled value="">
                      Choose...
                    </option>
                    {bankList.map((data, i) => (
                      <option key={i} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="account_name">
                  Account Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="account_name"
                  id="account_name"
                  onChange={bankCreateAccountInputsHandler}
                  placeholder="Account Name"
                  value={createBankData.account_name}
                  required
                />
              </div>
              <div className="form-group">
                <label
                  className="font-weight-semibold"
                  htmlFor="account_number"
                >
                  Account Number:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="account_number"
                  id="account_number"
                  onChange={bankCreateAccountInputsHandler}
                  placeholder="Account Number"
                  value={createBankData.account_number}
                  required
                />
              </div>
              <div className="form-group">
                <button
                  className={[
                    "btn btn-primary ",
                    loading ? " is-loading" : "",
                  ].join(" ")}
                  onClick={onCreateBankAccount}
                  disabled={disabledBankFormButton}
                >
                  <i className="anticon anticon-loading m-r-5"></i>
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </RightDrawer>
        <RightDrawer id="update_bank_form" title="Edit Bank Account">
          <div>
            <div>
              <input
                type="hidden"
                className="form-control"
                name="bank_account_id"
                id="bank_account_id"
                onChange={bankUpdateAccountInputsHandler}
                value={updateBankData.bank_account_id}
                required
              />
              <div className="form-group">
                <label htmlFor="bank_account">Currency</label>
                <select
                  className="custom-select"
                  name="currency_id"
                  value={updateBankData.currency_id}
                  onChange={bankUpdateAccountInputsHandler}
                >
                  <option disabled value="">
                    Choose...
                  </option>
                  {currencies.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.symbol} ({data.code})
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="bank_account">Bank Account</label>
                <select
                  className="custom-select"
                  name="bank_id"
                  value={updateBankData.bank_id}
                  onChange={bankUpdateAccountInputsHandler}
                >
                  <option>Choose...</option>
                  {bankList.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="account_name">
                  Account Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="account_name"
                  id="edit_account_name"
                  onChange={bankUpdateAccountInputsHandler}
                  placeholder="Account Name"
                  value={updateBankData.account_name}
                  required
                />
              </div>
              <div className="form-group">
                <label
                  className="font-weight-semibold"
                  htmlFor="account_number"
                >
                  Account Number:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="account_number"
                  id="edit_account_number"
                  onChange={bankUpdateAccountInputsHandler}
                  placeholder="Account Number"
                  value={updateBankData.account_number}
                  required
                />
              </div>
              <div className="form-group">
                <button
                  className={[
                    "btn btn-primary ",
                    loading ? " is-loading" : "",
                  ].join(" ")}
                  onClick={onUpdateBankAccount}
                  disabled={disabledBankFormButton}
                >
                  <i className="anticon anticon-loading m-r-5"></i>
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </RightDrawer>
      </div>
    </>
  );
};

export default Profile;
