import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  formatDate,
  initiateDatatable,
  toGlobalCurrency,
  toast,
  toggleModal,
} from "../../../utils/Helper";
import RightDrawer from "../../../components/RightDrawer";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet, fetchPost } from "../../../utils/API";
import Espay from "../../../components/Espay";

const INITIAL_TOPUP_FORM = {
  amount: "",
  currency_code: "",
  UUID: `${uuidv4()}`.substring(0, 28),
};

const Topup = () => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const [topupDetail, setTopupDetail] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [topupForm, setTopupForm] = useState(INITIAL_TOPUP_FORM);
  const [initiateTable, setInitiateTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const statusBadge = (status) => {
    if (status === "success")
      return <span className="badge badge-success capitalize">{status}</span>;
    else if (status === "process")
      return <span className="badge badge-primary capitalize">{status}</span>;
    else if (status === "pending")
      return <span className="badge badge-warning capitalize">{status}</span>;
    else if (status === "canceled")
      return <span className="badge badge-danger capitalize">{status}</span>;
    else
      return <span className="badge badge-default capitalize">{status}</span>;
  };

  const tableRow = (rowData, i) => {
    return (
      <tr key={i}>
        <td>
          <Link
            data-toggle="modal"
            data-target="#topup_detail"
            style={{ textTransform: "uppercase" }}
            onClick={() => setTopupDetail(rowData)}
          >
            #{rowData.id}
          </Link>
        </td>
        <td>
          {rowData.currencies
            ? `${rowData.currencies?.symbol} (${rowData.currencies?.code})`
            : ""}
        </td>
        <td>{toGlobalCurrency(rowData.total_nominal)}</td>
        <td>{toGlobalCurrency(rowData.transaction_fee)}</td>
        <td>{statusBadge(rowData.status)}</td>
        <td>{formatDate(rowData.date_created)}</td>
      </tr>
    );
  };

  const clearForm = () => setTopupForm(INITIAL_TOPUP_FORM);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setTopupForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm(name, value);
  };

  const validateForm = (name, value) => {
    let data = topupForm;
    data[name] = value;
    const isNotEmptyData = Object.values(data).every((value) => {
      if (value !== null && value !== "") {
        return true;
      }
      return false;
    });
    setDisabled(!isNotEmptyData);
  };

  const topupSubmit = async () => {
    setLoading(true);

    const res = await fetchPost(API_PATH.TOPUP_ADD, topupForm);
    toast(res.message, res.success);
    if (res.success) {
      toggleModal("#topup_form");
      const iframeData = {
        key: process.env.REACT_APP_ESPAY_API_KEY,
        paymentId: topupForm.UUID,
        backUrl: window.location.href,
        paymentAmount: topupForm.amount,
      };
      window.renderEspay(iframeData);
      setTimeout(() => {
        toggleModal("#topup_espay");
        clearForm();
      }, 200);
      // navigate("/topup/espay", {
      //   state: {
      //     topupData: { ...payload, previousRoute: window.location.href },
      //   },
      // });
    }
    setLoading(false);
  };

  const fetchList = useCallback(async () => {
    const res = await fetchGet(API_PATH.TOPUP_LIST);
    if (!res.success) {
      toast(res.message, res.success);
      if (res.status === 401) {
        navigate("/signin", { state: { ...res } });
      }
    } else {
      setDatas(res.data || []);
      if (initiateTable) {
        initiateDatatable({
          // currencyCols: [2, 3],
          filterTextCols: [0, 1, 2, 3],
          filterSelectCols: [4],
          filterDateCols: [5],
        });
        setInitiateTable(false);
      }
    }
  }, [initiateTable, navigate]);

  const fetchCurrencies = useCallback(async () => {
    const res = await fetchGet(API_PATH.CURRENCIES);
    if (!res.success) {
      toast(res.message, res.success);
      if (res.status === 401) {
        navigate("/signin", { state: { ...res } });
      }
    } else {
      setCurrencies(res.data || []);
    }
  }, [navigate]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="card-title">History Topup</h4>
            <button
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#topup_form"
            >
              <i className="anticon anticon-plus-circle tw-text-sm tw-mr-2"></i>
              <span>Create Topup</span>
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover data-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Currency</th>
                  <th>Nominal</th>
                  <th>Fee</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((trData, i) => {
                  return tableRow(trData, i);
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <RightDrawer id="topup_form" title="Topup Form">
        <div>
          <div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="amount">
                Amount:
              </label>
              <input
                type="text"
                className="form-control"
                name="amount"
                id="amount"
                onChange={inputsHandler}
                placeholder="Amount"
                value={topupForm.amount}
                required
              />
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="amount">
                Currency:
              </label>
              <select
                className="custom-select"
                name="currency_code"
                value={topupForm.currency_code}
                onChange={inputsHandler}
              >
                <option disabled value="">
                  Choose...
                </option>
                {currencies.map((data, i) => (
                  <option key={i} value={data.symbol}>
                    {data.symbol} ({data.code})
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <button
                className={[
                  "btn btn-primary ",
                  loading ? " is-loading" : "",
                ].join(" ")}
                onClick={topupSubmit}
                // onClick={submitWithdraw}
                disabled={disabled}
              >
                <i className="anticon anticon-loading m-r-5"></i>
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </RightDrawer>
      <RightDrawer id="topup_detail" title="Topup Detail">
        <section className="tw-gap-4 tw-flex tw-flex-col">
          <div>
            <label>Company Name</label>
            <p>#{topupDetail?.company?.name}</p>
          </div>
          <div>
            <label>Nominal</label>
            <div>
              {topupDetail?.currencies?.symbol}{" "}
              {toGlobalCurrency(topupDetail?.total_nominal)}
            </div>
          </div>
          <div>
            <label>Transaction Fee</label>
            <div>
              {topupDetail?.currencies?.symbol}{" "}
              {toGlobalCurrency(topupDetail?.transaction_fee)}
            </div>
          </div>
          <div>
            <label>Status</label>
            <div>{statusBadge(topupDetail?.status)}</div>
          </div>
          <div>
            <label>Name</label>
            <div>{topupDetail?.user?.name}</div>
          </div>
          <div>
            <label>Date</label>
            <div>{formatDate(topupDetail?.date_created)}</div>
          </div>
        </section>
      </RightDrawer>
      <Espay id="topup_espay" />
    </div>
  );
};

export default Topup;
