/* eslint-disable eqeqeq */
import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  initiateDatatable,
  toast,
  formatDate,
  initiateDatepicker,
  toggleModal,
} from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet, fetchPost } from "../../../utils/API";
import LoadingComponent from "../../../components/LoadingComponent";
import RightDrawer from "../../../components/RightDrawer";
import ReactTooltip from "react-tooltip";

const INITIAL_STATE = {
  name: "",
  email: "",
  phone_number: "",
  occupation: "",
  role_id: "",
  password: "",
  company_id: "",
  is_api_prod_active: false,
  ktp_file_url: "",
  ktp_selfie_file_url: "",
  approval_status: "",
};

const approvalStatuses = [
  {
    label: "Approve",
    value: "approve",
  },
  {
    label: "Submitted",
    value: "submitted",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Reject",
    value: "reject",
  },
];

const AdminMasterUser = () => {
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [userList, setUserList] = useState([{ role: {}, company: {} }]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [editUserId, setEditUserId] = useState("");
  const [userField, setUserField] = useState(INITIAL_STATE);

  useLayoutEffect(() => {
    const fetchData = async () => {
      setLoadingScreen(true);
      var res = await fetchGet(API_PATH.MASTER_USER);
      if (!res.success) {
        toast(res.message, res.success);
        if (res.status === 401) navigate("/signin", { state: { ...res } });
      } else {
        var res2 = await fetchGet(API_PATH.ROLE_LIST);
        if (!res2.success) {
          toast(res2.message, res2.success);
          if (res2.status === 401) navigate("/signin", { state: { ...res2 } });
        } else {
          setRoleList(res2.data.filter((dt) => dt.slug !== "super-admin"));
        }

        var res3 = await fetchGet(API_PATH.MASTER_COMPANY);
        if (!res3.success) {
          toast(res3.message, res3.success);
          if (res3.status === 401) navigate("/signin", { state: { ...res3 } });
        } else {
          setCompanyList(res3.data);
        }
        setUserList(res.data);
        initiateDatatable({
          currencyCols: [],
          filterTextCols: [0, 1, 2, 3, 5],
          filterSelectCols: [4],
          filterDateCols: [6],
        });
        initiateDatepicker();
      }
      setLoadingScreen(false);
    };
    fetchData();
  }, [initiateDatatable, navigate]);

  const userInputsCreationHandler = (e) => {
    const { name, value } = e.target;
    setUserField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateCreationForm(name, value);
  };

  const userInputsHandler = (e) => {
    const { name, value } = e.target;
    setUserField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm(name, value);
  };

  const validateCreationForm = (name, value) => {
    let data = { ...userField };
    data[name] = value;
    delete data.password;
    delete data.is_api_prod_active;
    delete data.approval_status;
    delete data.ktp_selfie_file_url;
    delete data.ktp_file_url;
    const isNotEmptyData = Object.values(data).every((value) => {
      if (value !== null && value !== "") {
        return true;
      }
      return false;
    });
    setDisabled(!isNotEmptyData);
  };

  const validateForm = (name, value) => {
    let data = { ...userField };
    data[name] = value;
    delete data.company_id;
    delete data.password;
    delete data.ktp_file_url;
    delete data.ktp_selfie_file_url;
    if (data.role_id == 3) {
      delete data.is_api_prod_active;
    }
    const isNotEmptyData = Object.values(data).every((value) => {
      if (value !== null && value !== "") {
        return true;
      }
      return false;
    });
    setDisabled(!isNotEmptyData);
  };

  const resetUserField = () => {
    let temp = {};
    Object.keys(userField).forEach((key) => {
      temp[key] = "";
    });
    setUserField(temp);
  };

  const onCreateUser = async () => {
    setLoading(true);
    var res = await fetchPost(API_PATH.CREATE_USER, userField);
    if (res.success) {
      var company = companyList.find(
        (dt) => parseInt(userField.company_id) === dt.id
      );
      var role = roleList.find((dt) => parseInt(userField.role_id) === dt.id);
      var usTemp = [
        ...userList,
        { ...userField, company: company, role: role },
      ];
      setUserList(usTemp);
      toggleModal(`#create_user_form`);
      resetUserField();
    }
    toast(res.message, res.success);
    setLoading(false);
  };

  const onUpdateUser = async () => {
    setLoading(true);
    let data = {
      ...userField,
      user_id: editUserId,
      is_api_prod_active:
        userField.is_api_prod_active === "true" ? true : false,
    };
    delete data.password;
    var res = await fetchPost(API_PATH.UPDATE_USER, data);
    if (res.success) {
      var usTemp = userList.map((dt) => {
        return dt.id === editUserId ? { ...dt, ...userField } : dt;
      });
      setUserList(usTemp);
      toggleModal(`#update_user_form`);
      resetUserField();
      setEditUserId("");
    }
    toast(res.message, res.success);
    setLoading(false);
  };

  const onDeleteUser = async (id) => {
    var res = await fetchPost(API_PATH.DELETE_USER, { user_id: id });
    if (res.success) {
      setUserList(userList.filter((dt) => dt.id !== id));
    }
    toast(res.message, res.success);
    ReactTooltip.hide();
  };

  const renderDeleteTooltipContent = (id) => {
    return (
      <>
        <p style={{ display: "block" }}>
          Are you sure want to delete this data?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn btn-default btn-xs m-r-5"
            onClick={() => onDeleteUser(id)}
          >
            Yes
          </button>
          <button
            className="btn btn-default btn-xs"
            onClick={() => ReactTooltip.hide()}
          >
            No
          </button>
        </div>
      </>
    );
  };

  const statusBadge = (status) => {
    if (status === "approve")
      return <span className="badge badge-success capitalize">{status}</span>;
    else if (status === "submitted")
      return <span className="badge badge-primary capitalize">{status}</span>;
    else if (status === "pending")
      return <span className="badge badge-warning capitalize">{status}</span>;
    else if (status === "reject")
      return <span className="badge badge-danger capitalize">{status}</span>;
    else
      return <span className="badge badge-default capitalize">{status}</span>;
  };

  const tableRow = (rowData, i) => {
    let company = rowData.company !== null ? rowData.company.name : "-";
    return (
      <tr key={i}>
        <td>{rowData.name}</td>
        <td>{rowData.email}</td>
        <td>{rowData.role?.name}</td>
        <td>{company}</td>
        <td>{statusBadge(rowData.approval_status)}</td>
        <td>
          <span className="badge badge-default capitalize">
            {rowData.is_active ? "Active" : "Not Active"}
          </span>
        </td>
        <td>{formatDate(rowData.date_created)}</td>
        <td>
          <button
            className="btn btn-icon btn-info btn-rounded  m-t-5 m-r-5"
            data-toggle="modal"
            data-target="#update_user_form"
            onClick={() => {
              let temp = INITIAL_STATE;
              Object.keys(userField)
                .filter((v) => v !== "password")
                .forEach((key) => {
                  temp[key] = rowData[key];
                });
              setUserField(temp);
              setEditUserId(rowData.id);
            }}
          >
            <i className="anticon anticon-edit"></i>
          </button>
          <button
            className="btn btn-icon btn-danger btn-rounded  m-t-5 m-r-5"
            data-tip
            data-for={"deleteRow-" + i}
            data-event="click"
          >
            <i className="anticon anticon-delete"></i>
          </button>
          <ReactTooltip
            id={"deleteRow-" + i}
            place="top"
            effect="solid"
            backgroundColor="#ffbebe"
            clickable={true}
          >
            {renderDeleteTooltipContent(rowData.id)}
          </ReactTooltip>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div className="card">
        <div className="card-header">
          <div className="d-md-flex m-b-15 align-items-center justify-content-between">
            <h4 className="card-title">Master User</h4>
            <button
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#create_user_form"
              onClick={resetUserField}
            >
              <span>Add New</span>
            </button>
          </div>
        </div>
        <div className="card-body">
          <div>
            <div className="table-responsive">
              <table className="table table-hover data-table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Company</th>
                    <th>Approval Status</th>
                    <th>Active Status</th>
                    <th>Created Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((trData, i) => {
                    return tableRow(trData, i);
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* create update form  */}
      <RightDrawer id="create_user_form" title="Add User">
        <div>
          <div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="name">
                Name:
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                onChange={userInputsCreationHandler}
                placeholder="Name"
                value={userField.name}
                required
              />
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="email">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                name="email"
                onChange={userInputsCreationHandler}
                placeholder="Email"
                value={userField.email}
                required
              />
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="new_password">
                Password:
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={userInputsCreationHandler}
                placeholder="Password"
                value={userField.password}
                required
              />
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="phone_number">
                Phone:
              </label>
              <input
                type="text"
                className="form-control"
                name="phone_number"
                onChange={userInputsCreationHandler}
                placeholder="Phone"
                value={userField.phone_number}
                required
              />
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="occupation">
                Occupation/Job:
              </label>
              <input
                type="text"
                className="form-control"
                name="occupation"
                onChange={userInputsCreationHandler}
                placeholder="Occupation/Job"
                value={userField.occupation}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="role_id">Role</label>
              <select
                className="custom-select"
                name="role_id"
                value={userField.role_id || ""}
                onChange={userInputsCreationHandler}
              >
                <option disabled value="">
                  Choose...
                </option>
                {roleList.map((data, i) => (
                  <option key={i} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="company_id">Company</label>
              <select
                className="custom-select"
                name="company_id"
                value={userField.company_id || ""}
                onChange={userInputsCreationHandler}
              >
                <option disabled value="">
                  Choose...
                </option>
                {companyList.map((data, i) => (
                  <option key={i} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <button
                className={[
                  "btn btn-primary ",
                  loading ? " is-loading" : "",
                ].join(" ")}
                onClick={onCreateUser}
                disabled={disabled}
              >
                <i className="anticon anticon-loading m-r-5"></i>
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </RightDrawer>
      <RightDrawer id="update_user_form" title="Edit User">
        <div>
          <div>
            <input
              type="hidden"
              className="form-control"
              name="user_id"
              value={editUserId}
              readOnly
              required
            />
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="name">
                Name:
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                onChange={userInputsHandler}
                placeholder="Name"
                value={userField.name}
                required
              />
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="email">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                name="email"
                onChange={userInputsHandler}
                placeholder="Email"
                value={userField.email}
                required
              />
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="phone_number">
                Phone:
              </label>
              <input
                type="text"
                className="form-control"
                name="phone_number"
                onChange={userInputsHandler}
                placeholder="Phone"
                value={userField.phone_number}
                required
              />
            </div>
            <div className="form-group">
              <label className="font-weight-semibold" htmlFor="occupation">
                Occupation/Job:
              </label>
              <input
                type="text"
                className="form-control"
                name="occupation"
                onChange={userInputsHandler}
                placeholder="Occupation/Job"
                value={userField.occupation}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="role_id">Role</label>
              <select
                className="custom-select"
                name="role_id"
                value={userField.role_id || ""}
                onChange={userInputsHandler}
              >
                <option disabled value="">
                  Choose...
                </option>
                {roleList.map((data, i) => (
                  <option key={i} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
            {userField.role_id == 2 && (
              <div className="form-group">
                <label htmlFor="company_id">Company</label>
                <select
                  className="custom-select"
                  name="company_id"
                  value={userField.company_id || ""}
                  onChange={userInputsHandler}
                >
                  <option disabled value="">
                    Choose...
                  </option>
                  {companyList.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {userField.role_id == 2 && (
              <div className="form-group">
                <label htmlFor="is_api_prod_active">
                  API Production Activation
                </label>
                <select
                  className="custom-select"
                  name="is_api_prod_active"
                  value={userField.is_api_prod_active || ""}
                  onChange={userInputsHandler}
                >
                  <option disabled value="">
                    Choose...
                  </option>
                  {[
                    { id: true, name: "True" },
                    { id: false, name: "False" },
                  ].map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="form-group">
              <label htmlFor="role_id">Approval Status</label>
              <select
                className="custom-select"
                name="approval_status"
                value={userField.approval_status || ""}
                onChange={userInputsHandler}
              >
                <option disabled value="">
                  Choose...
                </option>
                {approvalStatuses.map((data, i) => (
                  <option key={i} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </select>
            </div>
            {userField.role_id == 3 && (
              <>
                <hr />
                <h3 className="my-4 tw-font-medium tw-text-lg">
                  Know Your Customer
                </h3>
                <div className="form-group">
                  <label htmlFor="is_api_prod_active">KTP File</label>
                  {userField.ktp_file_url ? (
                    <a
                      href={userField.ktp_file_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={userField.ktp_file_url} alt="ktp-file" />
                    </a>
                  ) : (
                    <p className="tw-text-sm">None</p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="is_api_prod_active">KTP Selfie File</label>
                  {userField.ktp_selfie_file_url ? (
                    <a
                      href={userField.ktp_selfie_file_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={userField.ktp_selfie_file_url}
                        alt="ktp-selfie-file"
                      />
                    </a>
                  ) : (
                    <p className="tw-text-sm">None</p>
                  )}
                </div>
              </>
            )}
            <div className="form-group">
              <button
                className={[
                  "btn btn-primary ",
                  loading ? " is-loading" : "",
                ].join(" ")}
                onClick={onUpdateUser}
                disabled={disabled}
              >
                <i className="anticon anticon-loading m-r-5"></i>
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </RightDrawer>
    </>
  );
};

export default AdminMasterUser;
