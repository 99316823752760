import React, { useLayoutEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { initiateDatatable, toast, formatDate, initiateDatepicker } from '../../../utils/Helper'
import { API_PATH } from '../../../utils/Constant'
import { fetchGet } from '../../../utils/API'
import LoadingComponent from '../../../components/LoadingComponent'

const AdminTransaction = () => {
  const navigate = useNavigate()
  const [transactionData, setTransactionData] = useState([{transaction_details: [], user: {}, company: {}}]);
  const [loadingScreen, setLoadingScreen] = useState(false);


  const statusBadge = (status) => {
    if(status==='success') return <span className="badge badge-success capitalize">{status}</span>
    else if(status==='process') return <span className="badge badge-primary capitalize">{status}</span>
    else if(status==='pending') return <span className="badge badge-warning capitalize">{status}</span>
    else if(status==='canceled') return <span className="badge badge-danger capitalize">{status}</span>
    else return <span className="badge badge-default capitalize">{status}</span>
  }

  useLayoutEffect( () => {
    const fetchData = async () => {
      setLoadingScreen(true)
      var res = await fetchGet(API_PATH.TRANSACTION)
      if(!res.success) {
        toast(res.message,res.success)
        if(res.status===401) navigate('/signin', { state: { ...res } })
      } else {
        setTransactionData(res.data)
        initiateDatatable({
          currencyCols:[2,3],
          filterTextCols:[0,1,2,3,6,7],
          filterSelectCols:[4],
          filterDateCols:[5]
        })
        initiateDatepicker()
      }
      setLoadingScreen(false)
    }
    fetchData()
    
  },[initiateDatatable, navigate])

  const tableRow = (rowData, i) =>{
    let company = rowData.company !== null ? rowData.company.name : '-'
    return (
      <tr key={i}>
        <td>
          <Link to={'/admin/transaction/'+rowData.code} style={{textTransform:'uppercase'}}>#{rowData.code}</Link>
        </td>
        <td>{rowData.transaction_details.length}</td> 
        <td>{rowData.total_nominal}</td>
        <td>{rowData.total_fee||0}</td>
        <td>{statusBadge(rowData.status)}</td>
        <td>{formatDate(rowData.date_created)}</td>
        <td>{company}</td>
        <td>{rowData.user.name}</td>
      </tr>
    )
  }

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div className="card">
        <div className="card-body">    
          <div> 
            <div className="table-responsive">
              <table className="table table-hover data-table ">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Transfer Count</th>
                    <th>Total Amount</th>
                    <th>Transaction Fee</th>
                    <th>Status</th>
                    <th>Date</th> 
                    <th>Company</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    transactionData.map((trData,i) => {
                      return tableRow(trData,i)
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminTransaction