import React from 'react'

const RightDrawer = (props) => {
  return (
    <div className="modal modal-right fade" id={props.id}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header justify-content-between align-items-center">
            <h5 className="modal-title">{props.title}</h5>
          </div>
          <div className="modal-body scrollable">
            {props.children}
          </div>
        </div>
      </div>            
    </div>
  )
}

export default RightDrawer