import React, { useLayoutEffect, useState } from "react";
import {
  initiateDatatable,
  initiateDatepicker,
  toGlobalCurrency,
} from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet } from "../../../utils/API";
import { formatDate, toast } from "../../../utils/Helper";
import { useParams, useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/LoadingComponent";

const TransactionDetail = () => {
  const navigate = useNavigate();
  let { code } = useParams();
  const [transactions, setTransactions] = useState([{ bank: "" }]);
  const [data, setData] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);

  useLayoutEffect(() => {
    const fetchData = async () => {
      setLoadingScreen(true);
      var res = await fetchGet(API_PATH.SINGLE_TRANSACTION + code);
      if (!res.success) {
        toast(res.message, res.success);
        if (res.status === 401) navigate("/signin", { state: { ...res } });
      } else {
        setTransactions(res.data.transaction_details);
        setData(res.data);
        initiateDatatable({
          currencyCols: [],
          filterTextCols: [0, 1, 2, 3, 4],
          filterSelectCols: [5],
          filterDateCols: [6],
        });
        initiateDatepicker();
      }
      setLoadingScreen(false);
    };
    fetchData();
  }, [initiateDatatable, navigate, code]);

  const statusBadge = (status) => {
    if (status === "success")
      return (
        <span className="badge badge-success capitalize tw-text-sm">
          {status}
        </span>
      );
    else if (status === "process")
      return (
        <span className="badge badge-primary capitalize tw-text-sm">
          {status}
        </span>
      );
    else if (status === "pending")
      return (
        <span className="badge badge-warning capitalize tw-text-sm">
          {status}
        </span>
      );
    else if (status === "failed")
      return (
        <span className="badge badge-danger capitalize tw-text-sm">
          {status}
        </span>
      );
    else
      return (
        <span className="badge badge-default capitalize tw-text-sm">
          {status}
        </span>
      );
  };

  const tableRow = (rowData, i) => {
    return (
      <tr key={i}>
        <td>{rowData.bank?.name}</td>
        <td>{rowData.account_number}</td>
        <td>{rowData.account_name}</td>
        <td>{rowData.nominal}</td>
        <td>{rowData.fee || 0}</td>
        <td>{statusBadge(rowData.status)}</td>
        <td>{formatDate(rowData.date_created)}</td>
      </tr>
    );
  };

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Description</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <h5>ID</h5>
              <p>{data?.code}</p>
            </div>

            <div className="col-md-3">
              <h5>Type</h5>
              <p>
                {statusBadge(data?.type?.toUpperCase())} {}
              </p>
            </div>

            <div className="col-md-3">
              <h5>Status</h5>
              <p>
                {statusBadge(data?.status)} {}
              </p>
            </div>
            <div className="col-md-3">
              <h5>Status Description</h5>
              <p>{data?.description || "-"}</p>
            </div>
          </div>
          <div className="row tw-mt-6">
            <div className="col-md-3">
              <h5>Total Nominal</h5>
              <p>
                {data?.currencies?.symbol}{" "}
                {toGlobalCurrency(data?.total_nominal)}
              </p>
            </div>
            <div className="col-md-3">
              <h5>Transaction Fee</h5>
              <p>
                {data?.currencies?.symbol}{" "}
                {toGlobalCurrency(data?.transaction_fee)}
              </p>
            </div>
            <div className="col-md-3">
              <h5>Date</h5>
              <p>{formatDate(data?.date_created)}</p>
            </div>

            <div className="col-md-3">
              <h5>Created By</h5>
              <p>{data?.user?.name}</p>
            </div>
          </div>
        </div>
      </div>
      {transactions.length > 1 && (
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Transactions</h4>
          </div>
          <div className="card-body">
            <div>
              <div className="table-responsive">
                <table className="table table-hover data-table">
                  <thead>
                    <tr>
                      <th>Bank Name</th>
                      <th>Account Number</th>
                      <th>Account Name</th>
                      <th>Amount</th>
                      <th>Transaction Fee</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((trData, i) => {
                      return tableRow(trData, i);
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionDetail;
