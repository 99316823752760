import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  stringCurrency,
  toast,
  initiateDatatable,
  formatDate,
  toGlobalCurrency,
} from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet, fetchPost } from "../../../utils/API";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import LoadingComponent from "../../../components/LoadingComponent";
import ReactTooltip from "react-tooltip";

const AdminDashboard = () => {
  const navigate = useNavigate();

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pieData, setPieData] = useState([
    { value: 0 },
    { value: 0 },
    { value: 0 },
  ]);
  const [lineChartMode, setmodeLineChart] = useState("number");
  const [lineData, setLineData] = useState({
    number: [],
    nominal: [],
  });
  const [cardInfo, setCardInfo] = useState({
    balance: 0,
    transaction_amount: [],
    disbursement_request: 0,
    num_transaction: 0,
  });

  const statusBadge = (status) => {
    if (status === "success")
      return <span className="badge badge-success capitalize">{status}</span>;
    else if (status === "process")
      return <span className="badge badge-primary capitalize">{status}</span>;
    else if (status === "pending")
      return <span className="badge badge-warning capitalize">{status}</span>;
    else if (status === "canceled")
      return <span className="badge badge-danger capitalize">{status}</span>;
    else
      return <span className="badge badge-default capitalize">{status}</span>;
  };

  useEffect(() => {
    const fetchData = async () => {
      // setLoadingScreen(true)
      var res = await fetchGet(API_PATH.DASHBOARD);
      if (!res.success) {
        toast(res.message, res.success);
        if (res.status === 401) navigate("/signin", { state: { ...res } });
      } else {
        setCardInfo({
          balance: res.data.balance_bni,
          transaction_amount: res.data.transaction_amount,
          disbursement_request: res.data.disbursement_request,
          num_transaction: res.data.num_transaction,
        });
        setPieData(res.data.transaction_pie);
        setLineData(res.data.transaction_line);

        let res2 = await fetchGet(API_PATH.WITHDRAW_DEPOSIT);
        if (!res2.success) {
          toast(res2.message, res2.success);
          if (res2.status === 401) navigate("/signin", { state: { ...res2 } });
        } else {
          setTableData([...res2.data.withdraw, ...res2.data.deposit]);
          initiateDatatable({
            currencyCols: [3, 4],
            filterTextCols: [1, 3, 4, 6, 7, 8, 9, 11, 12],
            filterSelectCols: [2, 5],
            filterDateCols: [10],
          });
        }
      }
      setLoadingScreen(false);
    };
    fetchData();
  }, [initiateDatatable, navigate]);

  const renderConfirmTooltipContent = (id, type) => {
    return (
      <>
        <p style={{ display: "block" }}>
          Are you sure want to confirm this transaction?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn btn-default btn-xs m-r-5"
            onClick={() => onConfirmTrx(id, type)}
          >
            Yes
          </button>
          <button
            className="btn btn-default btn-xs"
            onClick={() => ReactTooltip.hide()}
          >
            No
          </button>
        </div>
      </>
    );
  };

  const onConfirmTrx = async (id, type) => {
    let res = await fetchPost(
      type == "deposit" ? API_PATH.CONFIRM_DEPOSIT : API_PATH.CONFIRM_WITHDRAW,
      { transaction_id: id }
    );
    if (!res.success) {
      toast(res.message, res.success);
      if (res.status === 401) navigate("/signin", { state: { ...res } });
    }
  };

  const renderCancelTooltipContent = (id, type) => {
    return (
      <>
        <p style={{ display: "block" }}>
          Are you sure want to cancel this transaction?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn btn-default btn-xs m-r-5"
            onClick={() => onCancelTrx(id, type)}
          >
            Yes
          </button>
          <button
            className="btn btn-default btn-xs"
            onClick={() => ReactTooltip.hide()}
          >
            No
          </button>
        </div>
      </>
    );
  };

  const onCancelTrx = async (id, type) => {
    let res = await fetchPost(
      type == "deposit" ? API_PATH.CANCEL_DEPOSIT : API_PATH.CANCEL_WITHDRAW,
      { transaction_id: id }
    );
    if (!res.success) {
      toast(res.message, res.success);
      if (res.status === 401) navigate("/signin", { state: { ...res } });
    }
  };

  const tableRow = (rowData, i) => {
    let company = rowData.company !== null ? rowData.company?.name : "-";
    let tr =
      rowData.transaction_details.length > 0
        ? rowData.transaction_details[0]
        : { bank_account: { bank: {} } };
    return (
      <tr key={i}>
        <td>
          {rowData.status === "pending" && (
            <>
              <button
                class="btn btn-icon btn-success btn-rounded btn-mini"
                data-tip
                data-for={"acceptRow-" + i}
                data-event="click"
              >
                <i class="anticon anticon-check"></i>
              </button>
              <ReactTooltip
                id={"acceptRow-" + i}
                place="right"
                effect="solid"
                backgroundColor="#ffbebe"
                clickable={true}
              >
                {renderConfirmTooltipContent(rowData.id, rowData.type)}
              </ReactTooltip>
              <button
                class="btn btn-icon btn-danger btn-rounded btn-mini "
                data-tip
                data-for={"cancelRow-" + i}
                data-event="click"
              >
                <i class="anticon anticon-close"></i>
              </button>
              <ReactTooltip
                id={"cancelRow-" + i}
                place="right"
                effect="solid"
                backgroundColor="#ffbebe"
                clickable={true}
              >
                {renderCancelTooltipContent(rowData.id, rowData.type)}
              </ReactTooltip>
            </>
          )}
        </td>
        <td>#{rowData.code}</td>
        <td>{rowData.type}</td>
        <td>{rowData.total_nominal}</td>
        <td>{rowData.total_fee || 0}</td>
        <td>{statusBadge(rowData.status)}</td>
        <td>{tr.bank_account?.bank?.name || ""}</td>
        <td>{tr.bank_account?.account_name || ""}</td>
        <td>{tr.bank_account?.account_number || ""}</td>
        <td>
          {rowData.transfer_proof_url ? (
            <img width={100} height={50} src={rowData.transfer_proof_url} />
          ) : (
            "-"
          )}
        </td>
        <td>{formatDate(rowData.date_created)}</td>
        <td>{company}</td>
        <td>{rowData.user?.name}</td>
      </tr>
    );
  };

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-gold">
                    <i className="anticon anticon-wallet" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">
                      Rp {stringCurrency(cardInfo.balance)}
                    </h2>
                    <p className="m-b-0 text-muted">BNI Balance</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i className="anticon anticon-schedule" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">{cardInfo.disbursement_request}</h2>
                    <p className="m-b-0 text-muted">Disbursment Request</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-purple">
                    <i className="anticon anticon-profile" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">{cardInfo.num_transaction}</h2>
                    <p className="m-b-0 text-muted">Transaction </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {cardInfo.transaction_amount.map((item, index) => (
            <div
              key={`transaction-amount-${index}`}
              className="col-md-6 col-lg-3"
            >
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-cyan">
                      <i className="anticon anticon-dollar" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">
                        {item.code} {toGlobalCurrency(item.value)}
                      </h2>
                      <p className="m-b-0 text-muted">Total Transaction</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="card">
              <div className="card-body">
                <h5 className="m-b-0">Transactions</h5>
                <div className="text-center" style={{ height: 317 }}>
                  <ResponsivePie
                    data={pieData}
                    margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    colors={{ datum: "data.color" }}
                    borderColor={{ from: "color" }}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                  />
                </div>
                <div className="row border-top p-t-25">
                  <div className="col-4">
                    <div className="d-flex justify-content-center">
                      <div className="media align-items-center">
                        <span className="badge badge-warning badge-dot m-r-10" />
                        <div className="m-l-5">
                          <h4 className="m-b-0">{pieData[0].value}</h4>
                          <p className="m-b-0 muted">{pieData[0].label}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex justify-content-center">
                      <div className="media align-items-center">
                        <span className="badge badge-success badge-dot m-r-10" />
                        <div className="m-l-5">
                          <h4 className="m-b-0">{pieData[1].value}</h4>
                          <p className="m-b-0 muted">{pieData[1].label}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex justify-content-center">
                      <div className="media align-items-center">
                        <span className="badge badge-danger badge-dot m-r-10" />
                        <div className="m-l-5">
                          <h4 className="m-b-0">{pieData[2].value}</h4>
                          <p className="m-b-0 muted">{pieData[2].label}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Transactions</h5>
                  <div>
                    <div className="btn-group">
                      <button
                        className={[
                          "btn btn-default ",
                          lineChartMode === "number" ? " active" : "",
                        ]}
                        onClick={() => setmodeLineChart("number")}
                      >
                        <span>Number</span>
                      </button>
                      <button
                        className={[
                          "btn btn-default ",
                          lineChartMode === "nominal" ? " active" : "",
                        ]}
                        onClick={() => setmodeLineChart("nominal")}
                      >
                        <span>Nominal</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="m-t-50" style={{ height: 330 }}>
                  {lineChartMode === "number" && (
                    <ResponsiveLine
                      data={lineData.number}
                      margin={{ top: 0, right: 100, bottom: 70, left: 80 }}
                      xScale={{ type: "point" }}
                      yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: true,
                        reverse: false,
                      }}
                      yFormat=" >-.2f"
                      curve="natural"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: "bottom",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Time",
                        legendOffset: 36,
                        legendPosition: "middle",
                      }}
                      axisLeft={{
                        orient: "left",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Number of transaction",
                        legendOffset: -70,
                        legendPosition: "middle",
                      }}
                      pointSize={10}
                      pointColor={{ theme: "background" }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: "serieColor" }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      legends={[
                        {
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: "left-to-right",
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: "circle",
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  )}
                  {lineChartMode === "nominal" && (
                    <ResponsiveLine
                      data={lineData.nominal}
                      margin={{ top: 0, right: 100, bottom: 70, left: 80 }}
                      xScale={{ type: "point" }}
                      yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: true,
                        reverse: false,
                      }}
                      yFormat=" >-.2f"
                      curve="natural"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: "bottom",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Time",
                        legendOffset: 36,
                        legendPosition: "middle",
                      }}
                      axisLeft={{
                        orient: "left",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Nominal of transaction",
                        legendOffset: -70,
                        legendPosition: "middle",
                      }}
                      pointSize={10}
                      pointColor={{ theme: "background" }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: "serieColor" }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      legends={[
                        {
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: "left-to-right",
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: "circle",
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div>
              <div className="table-responsive">
                <table className="table table-hover data-table ">
                  <thead>
                    <tr>
                      <th></th>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Total Amount</th>
                      <th>Transaction Fee</th>
                      <th>Status</th>
                      <th>Bank Name</th>
                      <th>Account Name</th>
                      <th>Account Number</th>
                      <th>Transfer Proof</th>
                      <th>Date</th>
                      <th>Company</th>
                      <th>Created By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((trData, i) => {
                      return tableRow(trData, i);
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
