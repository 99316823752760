import React, { useState, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMain } from "../../../config/context/MainContext";
import { toast, hashPassword } from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet, fetchPost } from "../../../utils/API";
import secureLocalStorage from "react-secure-storage";

function Signin() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { setToken, setUser } = useMain();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [inputField, setInputField] = useState({
    username: "",
    password: "",
  });

  const validateForm = useCallback(() => {
    const isNotEmptyData = Object.values(inputField).every((value) => {
      if (value !== null && value !== "") {
        return true;
      }
      return false;
    });
    setDisabled(!isNotEmptyData);
  }, [inputField]);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      secureLocalStorage.clear();
    }
  }, []);

  React.useEffect(() => {
    const checkRouterState = () => {
      if (state !== null) {
        if (state.status === 401) toast(state.message, state.success);
      }
    };
    checkRouterState();
  }, [state]);

  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm();
  };

  const submitButton = async (e) => {
    e.preventDefault();
    setLoading(true);
    const hashedPassword = hashPassword(inputField.password);

    var res = await fetchPost(API_PATH.SIGNIN, {
      username: inputField.username,
      password: hashedPassword,
      remember_me: checked ? 1 : 0,
    });
    if (!res.success) {
      toast(res.message, res.success);
      setLoading(false);
    } else {
      setToken(res.data.accessToken.token);
      var res2 = await fetchGet(API_PATH.PROFILE);
      if (!res2.success) {
        toast(res2.message, res.success);
        setLoading(false);
      } else {
        let userData = {
          name: res2.data.name,
          role: res2.data.role.slug,
          email: res2.data.email,
          approval_status: res2.data.approval_status,
        };
        setUser(userData);
        setTimeout(() => {
          setLoading(false);
          if (["admin", "personal"].includes(res2.data.role.slug)) {
            navigate("/dashboard");
          } else if (res2.data.role.slug === "super-admin")
            navigate("/admin/dashboard");
        }, 1500);
      }
    }
  };

  return (
    <div className="row w-100 m-0 align-items-center">
      <div className="col-md-7 col-lg-5 m-h-auto">
        <div className="card shadow-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between m-b-10">
              <img
                className="img-fluid"
                alt=""
                src="assets/images/logo/logo.png"
              />
              <h3 className="m-b-0">Sign In</h3>
            </div>
            <p style={{ visibility: "hidden" }}>
              {process.env.REACT_APP_API_URL}
            </p>
            <form>
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="username">
                  Email:
                </label>
                <div className="input-affix">
                  <i className="prefix-icon anticon anticon-user" />
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    id="username"
                    onChange={inputsHandler}
                    placeholder="Username"
                    value={inputField.username}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="password">
                  Password:
                </label>
                <Link
                  to="/forgot_password"
                  className="float-right font-size-13 text-muted"
                >
                  {" "}
                  Forgot Password?
                </Link>
                <div className="input-affix m-b-10">
                  <i className="prefix-icon anticon anticon-lock" />
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    onChange={inputsHandler}
                    placeholder="Password"
                    value={inputField.password}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="checkbox">
                    <input
                      id="confirmCheck"
                      type="checkbox"
                      name="confirmCheck"
                      checked={checked}
                      onChange={(e) => {
                        setChecked(!checked);
                      }}
                    />
                    <label htmlFor="confirmCheck">
                      <span>Remember Me</span>
                    </label>
                  </div>
                  <button
                    className={[
                      "btn btn-primary ",
                      loading ? " is-loading" : "",
                    ].join(" ")}
                    onClick={submitButton}
                    disabled={disabled}
                  >
                    <i className="anticon anticon-loading m-r-5"></i>
                    <span>Sign In</span>
                  </button>
                </div>
                <div
                  className="m-t-20"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span className="font-size-13 text-muted">
                    Don't have an account?
                    <Link to="/signup"> Signup</Link>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
