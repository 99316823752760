import React, { useEffect, useState } from "react";
import { CurlGenerator } from "curl-generator";
import { useNavigate } from "react-router-dom";
import { toast } from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet } from "../../../utils/API";
import Highlight from "react-highlight";

import LoadingComponent from "../../../components/LoadingComponent";

const ApiDocumentation = () => {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingScreen(true);

      var res = await fetchGet(API_PATH.API_DOCS);
      if (!res.success) {
        toast(res.message, res.success);
        if (res.status === 401) navigate("/signin", { state: { ...res } });
      } else {
        setApiData(res.data);
        setLoadingScreen(false);
      }
    };
    fetchData();
  }, [navigate]);

  const renderTabsItem = (data, i) => {
    let slug = data.title.toLowerCase().split(" ").join("-") + "-" + data.i;
    return (
      <li key={i} className="nav-item">
        <a
          className={["nav-link", i === 0 ? "active" : ""].join(" ")}
          id={slug + "-tab-vertical"}
          data-toggle="tab"
          href={"#" + slug + "-vertical"}
          role="tab"
          aria-controls={slug + "-vertical"}
          aria-selected="true"
        >
          {data.title}
        </a>
      </li>
    );
  };

  const renderTabsWrapper = (data, i) => {
    let slug = data.title.toLowerCase().split(" ").join("-") + "-" + data.i;
    let isProductionExist = Object.keys(data.production).length > 0;
    let isSandboxExist = Object.keys(data.sandbox).length > 0;

    return (
      <div
        key={i}
        className={["tab-pane fade", i === 0 ? "show active" : ""].join(" ")}
        id={slug + "-vertical"}
        role="tabpanel"
        aria-labelledby={slug + "-tab-vertical"}
      >
        <div
          className="page-header no-gutters has-tab"
          style={{ marginTop: 0 }}
        >
          <div className="d-md-flex m-b-15 align-items-center justify-content-between">
            <div className="media align-items-center m-b-15">
              <div
                className="avatar avatar-image avatar-purple"
                style={{ width: 60, height: 60, padding: 8 }}
              >
                <i
                  className="anticon anticon-code"
                  style={{ fontSize: 40, lineHeight: "normal" }}
                ></i>
              </div>
              <div className="m-l-15">
                <h4 className="m-b-0">{data.title}</h4>
                <p className="text-muted m-b-0">
                  {
                    // data.is_production
                    // ? <span className="badge badge-geekblue badge-pill uppercase">Production</span>
                    // : <span className="badge badge-red badge-pill uppercase">Sandbox</span>
                  }
                  {data.api_type === "get" ? (
                    <span className="badge badge-green uppercase">
                      {data.api_type}
                    </span>
                  ) : (
                    <span className="badge badge-blue uppercase">
                      {data.api_type}
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="m-b-15">
              {/* <button className="btn btn-primary">
                <i className="anticon anticon-edit" />
                <span>Edit</span>
              </button> */}
            </div>
          </div>
          <ul className="nav nav-tabs">
            {isProductionExist && (
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href={"#" + slug + "-vertical-production"}
                >
                  Production
                </a>
              </li>
            )}
            {isSandboxExist && (
              <li className="nav-item">
                <a
                  className={[
                    "nav-link",
                    isProductionExist ? "" : "active",
                  ].join(" ")}
                  data-toggle="tab"
                  href={"#" + slug + "-vertical-sandbox"}
                >
                  Sandbox
                </a>
              </li>
            )}
          </ul>
        </div>

        <div className="tab-content">
          <div
            className={"tab-pane fade show active"}
            id={slug + "-vertical-production"}
          >
            {isProductionExist ? renderTabsContent(data.production) : ""}
          </div>
          <div
            className={[
              "tab-pane fade",
              isProductionExist ? "" : "show active",
            ].join(" ")}
            id={slug + "-vertical-sandbox"}
          >
            {isSandboxExist ? renderTabsContent(data.sandbox) : ""}
          </div>
        </div>
      </div>
    );
  };

  const renderTabsContent = (data) => {
    const curlCommand = CurlGenerator({
      url: `${process.env.REACT_APP_API_URL}${data.api_url}`,
      method: data.api_type,
      headers: {
        "Content-Type": "application/json",
        ...(!["/client/auth", "/sandbox/auth"].includes(data.api_url) && {
          Authorization: "Bearer YOUR-TOKEN",
        }),
      },
      ...(data.api_post && { body: JSON.parse(data.api_post) }),
    });
    return (
      <div className="row">
        <div className="col-md-7">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Description</h4>
            </div>
            <div className="card-body">
              <p>{data.description}</p>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Basic Info</h4>
              <div className="table-responsive">
                <table className="product-info-table m-t-20">
                  <tbody>
                    <tr>
                      <td>API URL:</td>
                      <td>{process.env.REACT_APP_API_URL}</td>
                    </tr>
                    <tr>
                      <td>Endpoint:</td>
                      <td className="text-dark font-weight-semibold">
                        {data.api_url}
                      </td>
                    </tr>
                    <tr>
                      <td>Method:</td>
                      <td>
                        {data.api_type === "get" ? (
                          <span className="badge badge-green uppercase">
                            {data.api_type}
                          </span>
                        ) : (
                          <span className="badge badge-blue uppercase">
                            {data.api_type}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Header:</td>
                      <td className="code-style-text">
                        {data.api_header ? data.api_header : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Content-Type:</td>
                      <td className="code-style-text">
                        {data.api_post_type === "form"
                          ? "application/x-www-form-urlencoded"
                          : "application/json"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">API Response</h4>
            </div>
            <div className="card-body">
              <Highlight language="javascript">{data.api_result}</Highlight>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">CURL</h4>
            </div>
            <div className="card-body tw-flex tw-items-center tw-gap-4">
              <Highlight language="javascript">{curlCommand}</Highlight>
              <button
                onClick={() => {
                  toast("Copied to clipboard");
                  navigator.clipboard.writeText(curlCommand);
                }}
                className="btn btn-icon btn-rounded btn-tone"
              >
                <i className="anticon anticon-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div className="card">
        <div className="card-body p-0">
          <div className="d-flex custom-vertical-tabs row">
            <ul
              className="nav nav-tabs flex-column col-md-2 col-xs-12"
              role="tablist"
            >
              {apiData.map((data, i) => {
                return renderTabsItem(data, i);
              })}
            </ul>
            <div className="tab-content p-l-15 col-md-9 col-xs-12">
              {apiData.map((data, i) => {
                return renderTabsWrapper(data, i);
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiDocumentation;
