import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  initiateDatatable,
  toast,
  formatDate,
  initiateDatepicker,
  toGlobalCurrency,
  toggleModal,
} from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet, fetchPost } from "../../../utils/API";
import OTP from "../../../components/OTP";
import LoadingComponent from "../../../components/LoadingComponent";

import RightDrawer from "../../../components/RightDrawer";
import WalletTabInOut from "./wallet-tab/InOut";

const Wallet = () => {
  const navigate = useNavigate();
  const withdrawOTPRef = useRef();

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [walletData, setWalletData] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [initiateTable, setInitiateTable] = useState(true);
  const [bankData, setBankData] = useState([{ bank: {} }]);
  const [summaryData, setSummaryData] = useState({
    balance: "",
    totalDeposit: [],
    numDeposit: "",
    totalWithdraw: [],
    numWithdraw: "",
    totalDisbursement: "",
    numDisbursement: "",
    numRefund: "",
    totalRefund: [],
  });

  console.log("bankData", bankData);

  const [withdrawField, setWithdrawField] = useState({
    currency_id: "",
    amount: "",
    bank_account_id: "",
  });

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (name === "currency_id") {
      setWithdrawField((prevState) => ({
        ...prevState,
        [name]: value,
        bank_account_id: "",
      }));
      console.log("currency_id", value);
      validateForm(name, value);
      validateForm("bank_account_id", "");
    } else {
      setWithdrawField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      validateForm(name, value);
    }
  };

  const validateForm = (name, value) => {
    let data = withdrawField;
    data[name] = value;
    const isNotEmptyData = Object.values(data).every((value) => {
      if (value !== null && value !== "") {
        return true;
      }
      return false;
    });
    setDisabled(!isNotEmptyData);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingScreen(true);
      var res = await fetchGet(API_PATH.WALLET);
      if (!res.success) {
        toast(res.message, res.success);
        if (res.status === 401) navigate("/signin", { state: { ...res } });
      } else {
        setSummaryData((prev) => ({
          ...prev,
          balance: res.data.balance,
          totalDeposit: res.data.totalDeposit || [],
          numDeposit: res.data.numDeposit,
          totalWithdraw: res.data.totalWithdraw || [],
          numWithdraw: res.data.numWithdraw,
          totalDisbursement: res.data.totalDisbursement,
          numDisbursement: res.data.numDisbursement,
          numRefund: res.data.numRefund,
          totalRefund: res.data.totalRefund || [],
        }));
        initiateDatepicker();
        var res2 = await fetchGet(API_PATH.BANK_ACCOUNT);
        if (!res2.success) {
          toast(res2.message, res2.success);
        } else {
          setBankData(res2.data);
        }

        setLoadingScreen(false);
      }
    };
    fetchData();
  }, [initiateTable, navigate, walletData.length]);

  function checkString(string) {
    return /^[0-9]*$/.test(string);
  }

  const submitWithdrawModal = () => {
    const { currency_id, ...restPayload } = withdrawField;
    const currencyCode = currencies.find(
      (item) => item.id == currency_id
    )?.symbol;

    const currentWallet = summaryData.totalDeposit.find(
      (item) => currencyCode === item.code
    );

    console.log("typeof restPayload.amount", typeof restPayload.amount);

    if (!checkString(restPayload.amount)) {
      toast("Amount must be number", false);
      return;
    }

    if (
      !Number(restPayload.amount) ||
      !Number(currentWallet?.value) ||
      Number(currentWallet?.value) < Number(restPayload.amount)
    ) {
      toast("Insufficient Balance", false);
      return;
    }
    withdrawOTPRef.current.handleOTPDialog("START");
    toggleModal("#withdraw_form");
  };

  const submitWithdraw = useCallback(async () => {
    setLoading(true);

    const { currency_id, ...restPayload } = withdrawField;
    const currencyCode = currencies.find(
      (item) => item.id == currency_id
    )?.symbol;

    const payload = { ...restPayload, currency_code: currencyCode };

    var res2 = await fetchPost(API_PATH.WITHDRAW, payload);
    toast(res2.message, res2.success);
    if (!res2.success) {
      setLoading(false);
    } else {
      setWithdrawField({ amount: 0, bank_account_id: "", currency_id: "" });
      withdrawOTPRef.current.handleOTPDialog("END");
    }
    setLoading(false);
  }, [currencies, withdrawField]);

  const fetchCurrencies = useCallback(async () => {
    const res = await fetchGet(API_PATH.CURRENCIES);
    if (!res.success) {
      toast(res.message, res.success);
      if (res.status === 401) {
        navigate("/signin", { state: { ...res } });
      }
    } else {
      setCurrencies(res.data || []);
    }
  }, [navigate]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const renderTabDeposit = () => {
    return (
      <div className="row">
        {summaryData.totalDeposit.map((item, key) => (
          <div className="col-md-6 col-lg-3" key={`total-refund-${key}`}>
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-purple">
                    <i className="anticon anticon-wallet" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">
                      {toGlobalCurrency(item.value, item.code)}
                    </h2>
                    <p className="m-b-0 text-black-50">
                      Total {item.code} Deposit{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTabRefund = () => {
    return (
      <div className="row">
        {summaryData.totalRefund.map((item, key) => (
          <div className="col-md-6 col-lg-3" key={`total-refund-${key}`}>
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i className="anticon anticon-profile" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">
                      {toGlobalCurrency(item.value, item.code)}
                    </h2>
                    <p className="m-b-0 text-black-50">
                      Total {item.code} Refund{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTabWithdraw = () => {
    return (
      <div className="row">
        {summaryData.totalWithdraw.map((item, key) => (
          <div className="col-md-6 col-lg-3" key={`total-withdraw-${key}`}>
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-cyan">
                    <i className="anticon anticon-dollar" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">
                      {toGlobalCurrency(item.value, item.code)}
                    </h2>
                    <p className="m-b-0 text-black-50">
                      Total {item.code} Withdraw
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {/* {loadingScreen && <LoadingComponent />} */}
      <div className="-tw-mt-10 lg:tw-mt-0 ">
        <div className="page-header has-tab">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#tab-deposit"
              >
                Deposit
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#tab-refund">
                Refund
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#tab-withdraw">
                Withdraw
              </a>
            </li>
          </ul>
        </div>
        <div className="">
          <div className="tab-content m-t-15">
            <div className="tab-pane fade show active" id="tab-deposit">
              {renderTabDeposit()}
            </div>
            <div className="tab-pane fade" id="tab-refund">
              {renderTabRefund()}
            </div>
            <div className="tab-pane fade" id="tab-withdraw">
              {renderTabWithdraw()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-purple">
                    <i className="anticon anticon-profile" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">
                      {toGlobalCurrency(summaryData.numDisbursement)}
                    </h2>
                    <p className="m-b-0 text-black-50">
                      Number of Disbursement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-purple">
                    <i className="anticon anticon-wallet" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">
                      {toGlobalCurrency(summaryData.numDeposit)}
                    </h2>
                    <p className="m-b-0 text-black-50">Number of Deposit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-cyan">
                    <i className="anticon anticon-dollar" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">
                      {toGlobalCurrency(summaryData.numWithdraw)}
                    </h2>
                    <p className="m-b-0 text-black-50">Number of Withdraw</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i className="anticon anticon-profile" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">
                      {toGlobalCurrency(summaryData.numRefund)}
                    </h2>
                    <p className="m-b-0 text-black-50">Number of Refund</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="card-title">Wallet In Out</h4>
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#withdraw_form"
                  >
                    <i className="anticon anticon-plus-circle tw-text-sm tw-mr-2"></i>
                    <span>Withdraw</span>
                  </button>
                </div>
              </div>
              <div className="card-body">
                <div className="page-header has-tab">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          ["nav-link", isActive ? "active" : ""].join(" ")
                        }
                        data-toggle="tab"
                        to={"/wallet/in-out"}
                      >
                        Wallet In Out
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className={({ isActive }) =>
                          ["nav-link", isActive ? "active" : ""].join(" ")
                        }
                        to="/wallet/history"
                        data-toggle="tab"
                      >
                        History
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightDrawer id="withdraw_form" title="Withdraw Form">
          <div>
            <div>
              <div className="form-group">
                <label htmlFor="bank_account">Currency</label>
                <select
                  className="custom-select"
                  name="currency_id"
                  value={withdrawField.currency_id}
                  onChange={inputsHandler}
                >
                  <option disabled value="">
                    Choose...
                  </option>
                  {currencies.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.symbol} ({data.code})
                    </option>
                  ))}
                </select>
              </div>
              {withdrawField.currency_id && (
                <div className="form-group">
                  <label htmlFor="bank_account">Bank Account</label>
                  <select
                    className="custom-select"
                    name="bank_account_id"
                    value={withdrawField.bank_account_id}
                    onChange={inputsHandler}
                  >
                    <option>Choose...</option>
                    {bankData
                      .filter(
                        (item) =>
                          item.bank?.currency_id == withdrawField.currency_id
                      )
                      .map((data, i) => (
                        <option key={i} value={data.id}>
                          {data.account_number}-{data.bank.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="amount">
                  Amount:
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="amount"
                  id="amount"
                  onChange={inputsHandler}
                  placeholder="Amount"
                  value={withdrawField.amount}
                  required
                />
              </div>
              <div className="form-group">
                <button
                  className={[
                    "btn btn-primary ",
                    loading ? " is-loading" : "",
                  ].join(" ")}
                  onClick={submitWithdrawModal}
                  // onClick={submitWithdraw}
                  disabled={disabled}
                >
                  <i className="anticon anticon-loading m-r-5"></i>
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </RightDrawer>
        <OTP
          ref={withdrawOTPRef}
          id="submit_withdraw"
          onSubmitPressCallback={submitWithdraw}
        />
      </div>
    </>
  );
};

export default Wallet;
