import imageCompression from 'browser-image-compression';
import { useState } from 'react';

function useImageCompressor() {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleCompressor = async (file) => {
    setIsLoading(true);
    try {
      const compressedFile = await imageCompression(file, options);
      setIsLoading(false);
      return compressedFile;
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return [handleCompressor, { isLoading }];
}

export default useImageCompressor;
