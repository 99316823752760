import React from 'react'
import {Outlet, useLocation} from "react-router-dom";

import {useMain} from '../../config/context/MainContext'
import { getTitle } from '../../utils/Helper';

const PublicLayout = () => {
  const { setTitle } = useMain()
  const location = useLocation(); //#1 must use this to get fire useEffect on every url change

  React.useEffect(() => {
    setTitle(getTitle()) //#3 change title page
  }, [setTitle, location.key]); //#2

  return (
    <div className="app">
      <div className="container-fluid p-h-0 p-v-20 bg full-height d-flex" style={{backgroundImage: 'url("assets/images/background-auth.png")'}}>
        <div className="d-flex flex-column justify-content-between w-100">
          <div className="container d-flex h-100">
            <div className="notification-toast bottom-middle" id="notification-toast"></div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicLayout