import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotAccessible = () => {
  const navigate = useNavigate()
  return (
    <div className="app">
      <div className="container-fluid">
        <div className="d-flex full-height p-v-20 flex-column justify-content-between">
          <div className="d-none d-md-flex p-h-40">
            <img src="assets/images/logo/logo.png" alt />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="p-v-30">
                  <h1 className="font-weight-semibold display-1 text-primary lh-1-2">404</h1>
                  <h2 className="font-weight-light font-size-30">Whoops! Looks like you got lost</h2>
                  <p className="lead m-b-30">We couldnt find what you were looking for.</p>
                  <button onClick={()=>navigate(-1)} className="btn btn-primary btn-tone">Go Back</button>
                </div>
              </div>
              <div className="col-md-6 m-l-auto">
                
              </div>
            </div>
          </div>
          <div className="d-none d-md-flex  p-h-40 justify-content-between">
            <span className>© 2022 Motra</span>
            
          </div>
        </div>
      </div>
    </div>

  )
}

export default NotAccessible