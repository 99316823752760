import React, { useState, forwardRef, useImperativeHandle } from "react";
import { API_PATH } from "../utils/Constant";
import OTPInput, { ResendOTP } from "otp-input-react";
import { fetchPost, fetchGet } from "../utils/API";
import { toast, toggleModal } from "../utils/Helper";

const OTP = forwardRef((props, ref) => {
  const [OTPField, setOTP] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingOTPSubmit, setLoadingOTPSubmit] = useState(false);

  useImperativeHandle(ref, () => ({
    handleOTPDialog: (type) => {
      if (type === "START") {
        submitRequestOTP(props.id);
      } else if (type === "END") {
        onOTPFieldChange("");
      }
      toggleModal(`#${props.id}`);
    },
  }));

  const onConfirmOTPPress = async () => {
    setLoadingOTPSubmit(true);
    var res = await fetchGet(API_PATH.CHECK_OTP + OTPField);
    if (res.success) {
      props.onSubmitPressCallback();
    }
    toast(res.message, res.success);
    setLoadingOTPSubmit(false);
  };

  const onOTPFieldChange = (val) => {
    setOTP(val);
    setDisabled(val.length < 5);
  };

  const submitRequestOTP = async (type) => {
    setLoadingRefresh(true);
    var res = await fetchPost(API_PATH.REQUEST_OTP, { type: type });
    console.log(res);
    toast(res.message, res.success);
    setLoadingRefresh(false);
  };

  const renderOTPButton = (buttonProps) => {
    return (
      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          {...buttonProps}
          type="button"
          className={[
            "btn btn-default ",
            loadingRefresh ? " is-loading" : "",
          ].join(" ")}
        >
          <i className="anticon anticon-loading m-r-5"></i>
          <span>Resend</span>
        </button>
        <button
          {...buttonProps}
          className={[
            "btn btn-primary ",
            loadingOTPSubmit ? " is-loading" : "",
          ].join(" ")}
          onClick={onConfirmOTPPress}
          disabled={disabled}
        >
          <i className="anticon anticon-loading m-r-5"></i>
          <span>Submit</span>
        </button>
      </div>
    );
  };
  const renderOTPTime = (remainingtime) => {
    return (
      <div style={{ textAlign: "center", marginTop: 15, fontSize: "smaller" }}>
        {remainingtime} seconds remaining
      </div>
    );
  };

  return (
    <div id={props.id} className="modal fade">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <h2 style={{ display: "contents", marginBottom: 20 }}>OTP</h2>
            <button type="button" className="close" data-dismiss="modal">
              <i className="anticon anticon-close" />
            </button>
            <p style={{ padding: 20 }}>
              We have sent OTP code to your email, please input the valid OTP
            </p>
            <br />
            <OTPInput
              value={OTPField}
              onChange={onOTPFieldChange}
              autoFocus
              OTPLength={5}
              otpType="alphanumeric"
              inputClassName="form"
              style={{ display: "block", marginLeft: 10, textAlign: "center" }}
              disabled={false}
              inputStyles={{ borderWidth: 1, borderColor: "#e0e0e0" }}
              secure
            />
            <ResendOTP
              renderButton={renderOTPButton}
              renderTime={renderOTPTime}
              onResendClick={submitRequestOTP}
              style={{ display: "inlibe", justifyContent: "normal" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default OTP;
