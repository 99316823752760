import React from 'react'

const LoadingComponent = () => {
  return (
    <div className='loading-wrapper-page'>
      <div className='spinner'>
        <button className="btn btn-primary btn-icon btn-rounded m-r-5 is-loading">
          <i className="anticon anticon-loading"></i>
        </button>
        <span> Loading...</span>
      </div>
    </div>
  )
}

export default LoadingComponent