import React, { useCallback, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  initiateDatatable,
  toast,
  formatDate,
  initiateDatepicker,
  toGlobalCurrency,
} from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet, fetchPost } from "../../../utils/API";
import LoadingComponent from "../../../components/LoadingComponent";
import ReactTooltip from "react-tooltip";

const Transaction = () => {
  const navigate = useNavigate();
  const [isInitial, setInitial] = useState(true);
  const [transactionData, setTransactionData] = useState([
    { transaction_details: [], user: {} },
  ]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const statusBadge = (status) => {
    if (status === "success")
      return <span className="badge badge-success capitalize">{status}</span>;
    else if (status === "process")
      return <span className="badge badge-primary capitalize">{status}</span>;
    else if (status === "pending")
      return <span className="badge badge-warning capitalize">{status}</span>;
    else if (status === "canceled" || status === "failed")
      return <span className="badge badge-danger capitalize">{status}</span>;
    else
      return <span className="badge badge-default capitalize">{status}</span>;
  };

  const fetchData = useCallback(async () => {
    setLoadingScreen(true);
    var res = await fetchGet(API_PATH.TRANSACTION);
    if (!res.success) {
      toast(res.message, res.success);
      if (res.status === 401) navigate("/signin", { state: { ...res } });
    } else {
      setTransactionData(res.data || []);
      if (isInitial) {
        initiateDatatable({
          filterTextCols: [0, 1, 2, 6, 7],
          filterSelectCols: [3, 4],
          filterDateCols: [5],
        });
        initiateDatepicker();
        setInitial(false);
      }
    }
    setLoadingScreen(false);
  }, [isInitial, navigate]);

  const onConfirmTrx = async (id) => {
    let res = await fetchPost(API_PATH.CONFIRM_DISBURSEMENT, {
      transaction_id: id,
    });
    toast(res.message, res.success);
    if (res.success) {
      fetchData();
    } else if (!res.success) {
      if (res.status === 401) navigate("/signin", { state: { ...res } });
    }
    ReactTooltip.hide();
  };

  const onCancelTrx = async (id) => {
    let res = await fetchPost(API_PATH.CANCEL_DISBURSEMENT, {
      transaction_id: id,
    });
    toast(res.message, res.success);
    if (res.success) {
      fetchData();
    } else if (!res.success) {
      if (res.status === 401) navigate("/signin", { state: { ...res } });
    }
    ReactTooltip.hide();
  };

  const _renderConfirmDisbursement = (id) => {
    return (
      <>
        <p style={{ display: "block" }}>
          Are you sure want to confirm the disbursement?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn btn-default btn-xs m-r-5"
            onClick={() => onConfirmTrx(id)}
          >
            Yes
          </button>
          <button
            className="btn btn-default btn-xs"
            onClick={() => ReactTooltip.hide()}
          >
            No
          </button>
        </div>
      </>
    );
  };

  const _renderCancelDisbursement = (id) => {
    return (
      <>
        <p style={{ display: "block" }}>
          Are you sure want to cancel this transaction?
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn btn-default btn-xs m-r-5"
            onClick={() => onCancelTrx(id)}
          >
            Yes
          </button>
          <button
            className="btn btn-default btn-xs"
            onClick={() => ReactTooltip.hide()}
          >
            No
          </button>
        </div>
      </>
    );
  };

  useLayoutEffect(() => {
    fetchData();
  }, [fetchData]);

  const tableRow = (rowData, i) => {
    return (
      <tr key={i}>
        <td>
          {rowData.currencies?.symbol} ({rowData.currencies?.code})
        </td>
        <td>{toGlobalCurrency(rowData.total_nominal || 0)}</td>
        <td>{toGlobalCurrency(rowData.total_fee || 0)}</td>
        <td>{statusBadge(rowData.type)}</td>
        <td>{statusBadge(rowData.status)}</td>
        <td>{formatDate(rowData.date_created)}</td>
        <td>{rowData.user.name}</td>
        <td className="tw-gap-4 tw-flex tw-justify-end">
          {rowData.type === "disbursement" && rowData.status === "pending" && (
            <>
              <button
                class="btn btn-icon btn-success btn-rounded btn-mini"
                data-tip
                data-for={"acceptRow-" + i}
                data-event="click"
              >
                <i class="anticon anticon-check"></i>
              </button>
              <ReactTooltip
                id={"acceptRow-" + i}
                place="right"
                effect="solid"
                backgroundColor="#ffbebe"
                clickable={true}
              >
                {_renderConfirmDisbursement(rowData.id)}
              </ReactTooltip>
              <button
                class="btn btn-icon btn-danger btn-rounded btn-mini "
                data-tip
                data-for={"cancelRow-" + i}
                data-event="click"
              >
                <i class="anticon anticon-close"></i>
              </button>
              <ReactTooltip
                id={"cancelRow-" + i}
                place="right"
                effect="solid"
                backgroundColor="#ffbebe"
                clickable={true}
              >
                {_renderCancelDisbursement(rowData.id)}
              </ReactTooltip>
            </>
          )}
          <Link to={"/transaction/" + rowData.code}>
            <button
              className="btn btn-icon btn-primary btn-rounded btn-tone"
              data-tip
              data-for={"deleteRow-" + i}
              data-event="click"
            >
              <i className="anticon anticon-arrow-right"></i>
            </button>
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div className="card">
        <div className="card-body">
          <div>
            <div className="table-responsive">
              <table className="table table-hover data-table ">
                <thead>
                  <tr>
                    <th>Currency</th>
                    <th>Total Amount</th>
                    <th>Transaction Fee</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Created By</th>
                    <th>Detail</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionData.map((trData, i) => {
                    return tableRow(trData, i);
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transaction;
